const paintSurfaceCalculator = (surface, coverageValue, isMarge, isFromSuperficie = false, layersQty = 1) => {
  let res = surface / coverageValue;

  if (isMarge) {
    res = res + (res * 10 / 100);
  }

  if (isFromSuperficie) {
    return Math.round(res * 100) / 100 * layersQty;
  }

  return (Math.round(res * 100) / 100) * 2;
}

export default paintSurfaceCalculator;
