<template>
	<div v-if="!isAddToCartSidebarOpen ? !isQtyCalculatorSidebarOpen : ''" class="product__data__add">
		<!-- PRIX + AJOUT WISHLIST MOBILE -->
		<PictimePrice
				:flap="calculFlap(isAuth, product)"
				:is-from-price="isFromPrice"
				:packaging-regular-price="isAuth ? $fc(getProductPrice(product).packagePriceHT) : $fc(getProductPrice(product).packagePriceTTC)"
				:regular-price="isAuth ? $fc(getProductPrice(product).priceHT) : $fc(getProductPrice(product).priceTTC)"
				:taxe="getTaxe(isAuth)"
				:unit-measure="product.packagingMeasureUnit"
				class="product__data__add__price-recap smartphone-only"
		/>
		<AddToWishlist
				:is-show="isAuth"
				@addToWishlist="$emit('toggleWishlist')"
				class="smartphone-only"
		/>

		<!-- AJOUT PANIER -->
		<div class="product__data__add-cart">
			<div v-if="product.stocks !== null">
				<PictimeAddToCart
						:click-and-collect="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).store.isOk"
						:currentShop="currentShop"
            :disabled="disableAddToCart"
						:livraison="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).delivery.isOk"
						:packaging-regular-price="isAuth ? $fc(getProductPrice(product).packagePriceHT) : $fc(getProductPrice(product).packagePriceTTC)"
						:regular-price="isAuth ? getProductPrice(product).priceHT : getProductPrice(product).priceTTC"
						:selectedConditioningLabel="selectedConditioningLabel"
            :product="product"
            class="product__add-to-cart"
						@click="$emit('addItemToCart')"
				/>
			</div>
			<div v-else>
				<PictimeAddToCart
						:click-and-collect="false"
						:currentShop="currentShop"
            :disabled="disableAddToCart || !product.active"
						:livraison="false"
						:packaging-regular-price="isAuth ? $fc(getProductPrice(product).packagePriceHT) : $fc(getProductPrice(product).packagePriceTTC)"
						:regular-price="isAuth ? getProductPrice(product).priceHT : getProductPrice(product).priceTTC"
						:selectedConditioningLabel="selectedConditioningLabel"
            :product="product"
            class="product__add-to-cart"
				/>
			</div>

			<!-- AJOUT WISHLIST DESKTOP -->
			<div>
				<SfButton
						class="desktop-only product__data__add-cart__button sf-button sf-button__secondary"
						@mouseover="isIconHover = true" @mouseleave="isIconHover = false"
						@click="$emit('toggleWishlist')"
						v-if="isAuth"
				>
					<SvgImage
							class="add-to-wishlist__content"
							height="30"
							width="30"
							:icon="icon"
							:label="$t('Wishlist')"
					/>
					<span>Ajouter aux favoris</span>
				</SfButton>
			</div>
		</div>
	</div>

</template>
<script>
import PictimeAddToCart from "~/components/molecules/PictimeAddToCart.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import AddToWishlist from "~/modules/wishlist/components/AddToWishlist.vue";
import ProductPricesMobile from "~/modules/catalog/product/components/ProductPricesMobile.vue";
import PictimePrice from "~/components/molecules/PictimePrice.vue";
import { getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
import calculFlap from '~/helpers/utils/calculFlap';
import {computed, ref} from "@nuxtjs/composition-api";
import calculStock from '~/helpers/utils/calculStock';
import { SfButton } from '@storefront-ui/vue';
import PictimeQuantitySelector from "~/components/molecules/PictimeQuantitySelector.vue";

export default {
	name: 'ProductAddTOCart',
	components: {
    PictimeQuantitySelector,
		SfButton,
		PictimePrice,
		ProductPricesMobile,
		AddToWishlist,
		SvgImage,
		PictimeAddToCart
	},
	props: {
		isAddToCartSidebarOpen: {
			type: Boolean,
			default: false
		},
		isQtyCalculatorSidebarOpen: {
			type: Boolean,
			default: false
		},
		isAuth: {
			type: Boolean,
			default: false
		},
		currentShop: {
			type: String,
			default: ''
		},
		disableAddToCart: {
			type: Boolean,
			default: false,
		},
		selectedConditioningLabel:{
			type: String,
			default: ''
		},
		qty: {
			type: Number,
			default: 1
		},
		selectedOptionSku: {
			type: String,
			default: ''
		},
		optionsConfiguration: {
			type: Array,
			default: () => []
		},
		isCustom: {
			type: Boolean,
			default: false
		},
		product: {
			type: Object,
			default: () => {}
		},
		isFromPrice: {
			type: Boolean,
			default: false,
		}
	},

	setup() {
		const isIconHover = ref(false);
		const icon = computed(() => isIconHover.value ? 'heart_fill' : 'heart')

		const getTaxe = (isAuth) => {
			return !isAuth ? ' TTC' : ' HT'
		};

		return {
			getTaxe,
			getProductPrice,
			calculFlap,
			calculStock,
			icon,
			isIconHover,
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>
