const floorQtyCalculator = (surface, surfaceBotte, isMarge) => {
  let res = surface / surfaceBotte;

  if (isMarge) {
    res = res + (res * 10 / 100);
  }

  return Math.round(res);
}

export default floorQtyCalculator;
