














































































































































































































































































































import { storeToRefs } from 'pinia';
import { SfBadge, SfButton, SfLink, SfOverlay } from '@storefront-ui/vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { defineComponent, onMounted, ref, useContext, useRouter, watch, } from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiHelpers, useUiState, } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useTopBar } from '../TopBar/useTopBar';
import { cacheRole } from '~/helpers/customer/role';
import type { CategoryTree } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import Header from '~/components/organisms/Header.vue';
import ShopNameInformation from '~/components/ShopNameInformation';
import HeaderNavigationItems from '~/components/Header/Navigation/HeaderNavigationItems.vue';
import HeaderNavigationItemsMobile from '~/components/Header/Navigation/HeaderNavigationItemsMobile.vue';
import HeaderNavigationItemsLink from '~/components/Header/Navigation/HeaderNavigationItemsLink.vue';
import HeaderNavigationItemsPromo from '~/components/Header/Navigation/HeaderNavigationItemsPromo.vue';
import { onSSR } from '@vue-storefront/core';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  props: ['activeIcon'],
  components: {
    Header,
    HeaderNavigationItems,
    HeaderNavigationItemsMobile,
    HeaderNavigationItemsLink,
    HeaderNavigationItemsPromo,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SfLink,
    ShopNameInformation,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import('~/components/Header/SearchBar/SearchResults.vue'),
  },
  fetchOnServer: false,
  methods: {
    showStoreLocator() {
      this.$emit('Cromology::StoreLocator::Open');
    },
  },
  setup() {
    const customerStore = useCustomerStore();
    const { isLoggedIn } = storeToRefs(customerStore);
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { isCommercial } = cacheRole();
    const { load, loadTotalQty } = useCart();
    const cartStore = useCartStore();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

		const isAuth = ref(false);
    const searchResultsData = ref([]);
    const searchTerm = ref('');

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const showSearchOnMobile = ref(false);

    const accountIcon = ref();
    const categoryTree = ref<CategoryTree[]>([]);

    const catTree = ref(null);
    const catTreeAdditional = ref(null);

    const cartTotalItems = ref(0);

    const getCategoryTreeWithCMS = (type = 'normal') => {
      let tree = null;

      if (type === 'normal') {
        tree = categoryList.value?.[0]?.children.filter((category) => category.include_in_menu);
      } else if (type === 'additional') {
        tree = categoryList.value?.filter((category) => category.category_type === 1);
      }

      return tree;
    };

    const getLinkCategory = () => {
      let res = null;
      Object.keys(categoryList.value).forEach((category) => {
        if (categoryList.value[category]?.category_type === 2) {
          res = categoryList.value[category];
        }
      });
      return res;
    };
    const linkCategory = ref(null);

    const getPromoCategory = () => {
      let res = null;
      Object.keys(categoryList.value).forEach((category) => {
        if (categoryList.value[category]?.name === 'Promo') {
          res = categoryList.value[category];
        }
      });
      return res;
    };
    const promoCategory = ref(null);

    const openAccount = async () => {
			menuMobileClose();
      await router.push(app.localeRoute({ name: 'customer-my-profile' }));
    };

    const openEspacePro = async () => {
			menuMobileClose();
      await router.push(app.localeRoute({ name: 'customer-acces-espace-pro' }));
    };

    const openCart = async () => {
			menuMobileClose();
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const openWishlist = async () => {
			menuMobileClose();
      await router.push(app.localeRoute({ name: 'customer-my-wishlist' }));
    };

    const getMenu = async () => {
      await getCommonMenu();

      promoCategory.value = getPromoCategory();
      linkCategory.value = getLinkCategory();
      catTreeAdditional.value = getCategoryTreeWithCMS('additional');
    };

    const initUser = async() => {
      isAuth.value = await isAuthenticated();
      accountIcon.value = isAuth.value ? 'profile_fill' : 'profile';
      cartTotalItems.value = cartStore.$state.cart?.total_quantity || 0;

      const apiState = app.$vsf.$magento.config.state;

      if (isAuth.value && !apiState.getCartId()) {
        await load();
      }

      if (isAuth.value && cartTotalItems.value === 0) {
        await loadTotalQty();
      }

      await getMenu();
    }

    const getCommonMenu = async () => {
      await categoriesListLoad({ pageSize: 10, customQuery: { categoryList: 'customCategoryList' } });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      catTree.value = getCategoryTreeWithCMS();
    };

    onSSR(async () => {
      await getCommonMenu();
    });

    onMounted(async () => {
      await initUser();
    });

    watch(isLoggedIn, async () => {
      await initUser();
    });

    watch(cartStore.$state, () => {
      cartTotalItems.value = cartStore.$state.cart?.total_quantity || 0;
    })

    function toogleMobile() {
			menuMobileClose();
      showSearchOnMobile.value = !showSearchOnMobile.value;
      if (showSearchOnMobile.value && this.$refs?.searchbar) {
        this.$refs.searchbar.$el.querySelector('input#search').focus();
      }
    }

		const menuMobileClose = () => {
			isMobileMenuOpen.value && toggleMobileMenu();
			document.body.classList.remove('no-scroll');
		};

    return {
      accountIcon,
      cartTotalItems,
      categoryTree,
      getCatLink,
      openAccount,
      openEspacePro,
      openCart,
      openWishlist,
      isAuth,
      isCommercial,
      isSearchOpen,
      showSearchOnMobile,
      toogleMobile,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      hasCurrencyToSelect,
      hasStoresToSelect,
      catTree,
      catTreeAdditional,
      linkCategory,
      promoCategory,
      searchResultsData,
      searchTerm,
			menuMobileClose
    };
  },
});
