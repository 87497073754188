<template>
  <div class="modalApproval">
    <SfModal
      :cross="true"
      :visible="isPendingModalOpen"
      @close="togglePendingModal"
    >
      <div>
        <p class="title">Commande en attente d'approbation</p>
      </div>
      <div class="bloc-approval">
        <p class="p">Votre panier est actuellement en attente d'approbation par
          <span>{{ lastNameDirigeant }} {{ firstNameDirigeant }}.</span></p>
        <div class="info">
          <SvgImage
            icon="Icon_attention"
          />
          <p class="p">Toute modification du panier annulera votre demande en cours.</p>
        </div>
        <p class="question">Êtes-vous sûr(e) de vouloir supprimer votre demande en cours ?</p>

        <div class="bloc-button">
          <SfButton
            :aria-disabled="false"
            class="sf-button__secondary button"
            type="button"
            @click="togglePendingModal"
          >
            Annuler
          </SfButton>
          <SfButton
            class="sf-button__primary button"
            type="button"
            @click="cancelOrderApproval"
          >
            Confirmer
          </SfButton>
        </div>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfLink, SfModal } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: "PendingApprovalModal",
  components: {
    SfLink,
    SfButton,
    SfModal,
    SvgImage
  },
  props: {
    lastNameDirigeant: {
      type: String,
      default: ""
    },
    firstNameDirigeant: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    const { isPendingModalOpen, togglePendingModal } = useUiState();

    const cancelOrderApproval = () => {
      context.emit('cancelOrderApproval')
    }

    return {
      isPendingModalOpen,
      togglePendingModal,
      cancelOrderApproval
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/theme/default/scss/components/molecules/cart-approval-modal.scss";
</style>
