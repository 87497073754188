<template>
  <div class="sf-bar">
    <div>
      <slot name="back">
        <SfButton
          :class="{ 'display-none': !back }"
          aria-label="back"
          class="sf-button--pure sf-bar__icon"
          type="button"
          @click="$emit('click:back')"
        >
          <SfIcon icon="chevron_left" size="0.875rem"/>
        </SfButton>
      </slot>
    </div>
    <div>
      <slot name="title" v-bind="{ title }">
        <div class="sf-bar__title">{{ title }}</div>
      </slot>
    </div>
    <div>
      <slot name="close">
        <SfButton
          :class="{ 'display-none': !close }"
          aria-label="close"
          class="sf-button--pure sf-bar__icon"
          type="button"
          @click="$emit('click:close')"
        >
          <span class="sf-icon">
            <SvgImage
              height="18"
              icon="Icon_fermer_grise"
              width="18"
            />
          </span>
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<script>
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfButton from "@storefront-ui/vue/src/components//atoms/SfButton/SfButton.vue";
import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: "PictimeBar",
  components: {
    SfIcon,
    SfButton,
    SvgImage
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfBar.scss";
.sf-bar.smartphone-only {
  margin: 35px 0 0;
}
</style>
