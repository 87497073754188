<template>
	<div class="product__data__add__price-recap">
		<PictimePrice
				:flap="calculFlap(isAuth, product)"
				:is-from-price="isFromPrice"
				:packaging-regular-price="isAuth ? $fc(getProductPrice(product).packagePriceHT) : $fc(getProductPrice(product).packagePriceTTC)"
				:regular-price="isAuth ? $fc(getProductPrice(product).priceHT) : $fc(getProductPrice(product).priceTTC)"
				:taxe="getTaxe(isAuth)"
				:unit-measure="product.packagingMeasureUnit"
				class="smartphone-only"
		/>
		<AddToWishlist
				:is-show="isAuth"
				@addToWishlist="$emit('toggleWishlist')"
				class="smartphone-only"
		/>
	</div>

</template>
<script>
import AddToWishlist from "~/modules/wishlist/components/AddToWishlist.vue";
import PictimePrice from "~/components/molecules/PictimePrice.vue";
import { getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
import calculFlap from '~/helpers/utils/calculFlap';

export default {
	name: 'ProductPricesMobile',
	components: {
		PictimePrice,
		AddToWishlist
	},
	props: {
		isAuth: {
			type: Boolean,
			default: false
		},
		product: {
			type: Object,
			default: () => {}
		},
		isFromPrice: {
			type: Boolean,
			default: false,
		}
	},
	setup() {
		const getTaxe = (isAuth) => {
			return !isAuth ? ' TTC' : ' HT'
		};

		return {
			getTaxe,
			getProductPrice,
			calculFlap
		}
	}
}
</script>
<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>