import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseCancelForApprovalErrors, UseCancelForApprovalInterface } from './useCancelRequestForApproval';

export function useCancelRequestForApproval(): UseCancelForApprovalInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseCancelForApprovalErrors>({
    postCancelForApproval: null
  });

  const save = async (input) => {
    Logger.debug(`useCancelRequestForApproval/postCancelForApproval`, input);
    let cancelRequestForApproval = null;

    try {
      loading.value = true;

      // @ts-ignore
      cancelRequestForApproval = await app.$vsf.$magento.api.cancelRequestForApproval(input);

      Logger.debug(`[Result] useCancelRequestForApproval/postCancelForApproval : `, cancelRequestForApproval);

      error.value.postCancelForApproval = null;
    } catch (err) {
      error.value.postCancelForApproval = err;
      Logger.error(`useCancelRequestForApproval/postCancelForApproval`, err);
    } finally {
      loading.value = false;
    }

    return cancelRequestForApproval;
  }

  return {
    save,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useCancelRequestForApproval';
export default useCancelRequestForApproval;
