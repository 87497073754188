<template>
	<div class="product__data__prices">
		<PictimeFlap
				:discount-percent="isAuth ? Math.round(getProductPrice(product).discountPercentHT) : Math.round(getProductPrice(product).discountPercentTTC)"
				:flap="calculFlap(isAuth, product)"
				:is-from-price="isFromPrice"
				:old-price="isAuth ? $fc(getProductPrice(product).oldPriceHT) : $fc(getProductPrice(product).oldPriceTTC)"
				:packaging-old-price="isAuth ? $fc(getProductPrice(product).packageOldPriceHT) : $fc(getProductPrice(product).packageOldPriceTTC)"
				:taxe="getTaxe(isAuth)"
				:unit-measure="product.packagingMeasureUnit"
		/>
		<div>
			<PictimePrice
					:flap="calculFlap(isAuth, product)"
					:is-from-price="isFromPrice"
					:packaging-regular-price="isAuth ? $fc(getProductPrice(product).packagePriceHT) : $fc(getProductPrice(product).packagePriceTTC)"
					:regular-price="isAuth ? $fc(getProductPrice(product).priceHT) : $fc(getProductPrice(product).priceTTC)"
					:taxe="getTaxe(isAuth)"
					:unit-measure="product.packagingMeasureUnit"
			/>
		</div>
	</div>
</template>
<script>
import PictimePrice from "~/components/molecules/PictimePrice.vue";
import PictimeFlap from "~/components/molecules/PictimeFlap.vue";
import { getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
import calculFlap from '~/helpers/utils/calculFlap';

export default {
	name: 'ProductPrices',
	components: {
		PictimeFlap,
		PictimePrice
	},
	props: {
		isAuth: {
			type: Boolean,
			default: false
		},
		product: {
			type: Object,
			default: () => {}
		},
		isFromPrice: {
			type: Boolean,
			default: false,
		}
	},

	setup() {
		const getTaxe = (isAuth) => {
			return !isAuth ? ' TTC' : ' HT'
		};

		return {
			getTaxe,
			getProductPrice,
			calculFlap
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>