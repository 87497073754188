import { render, staticRenderFns } from "./BestSellers.vue?vue&type=template&id=2b53bdaa&scoped=true"
import script from "./BestSellers.vue?vue&type=script&lang=ts"
export * from "./BestSellers.vue?vue&type=script&lang=ts"
import style0 from "./BestSellers.vue?vue&type=style&index=0&id=2b53bdaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b53bdaa",
  null
  
)

export default component.exports