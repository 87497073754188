<template>
  <div id="CromologyColorPicker" class="CromologyColorPicker">
    <div :class="{ 'CromologyColorPicker-ContainerOverlay': open }" @click="close"></div>
    <div :class="{ 'CromologyColorPicker-Container--open': open }" class="CromologyColorPicker-Container">
      <div class="CromologyColorPicker-Header">
        <span class="CromologyColorPicker-HeaderTitle">Choisissez votre couleur</span>
        <div class="CromologyColorPicker-Search">
          <input
            v-model="search"
            class="CromologyColorPicker-SearchInput"
            placeholder="Saisissez un nom ou une référence"
            @click="$event.stopPropagation()"
          />
          <div class="CromologyColorPicker-SearchIcon">
            <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.588 14.177A6.588 6.588 0 1 0 7.588 1a6.588 6.588 0 0 0 0 13.177ZM12.295 12.294 17 17"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="1.5"
              />
            </svg>
          </div>
          <div v-if="searchList.length > 0" class="CromologyColorPicker-SearchList">
            <div
              v-for="(color, index) in searchList"
              :key="color.id"
              class="CromologyColorPicker-SearchListItem"
              @click="selectSearchColor($event, index)"
            >
              <div
                :style="{
                  'background-color': '#' + color.rgb,
                  border: '1px solid ' + ('#' + color.rgb === '#FFFFFF' ? '#DADADA' : '#' + color.rgb)
                }"
                class="CromologyColorPicker-ItemColor"
              ></div>
              <span>{{ color.lib_nuancier + ' ' + color.lib_teinte }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showColorPicker" class="CromologyColorPicker-OrCircle">ou</div>
      <div class="CromologyColorPicker-Picker">
        <div
          v-if="showColorPicker"
          :class="{ 'CromologyColorPicker-PickerBlock--active': step > 0 }"
          class="CromologyColorPicker-PickerBlock"
        >
          <div class="CromologyColorPicker-PickerBlockTitle">
            <div class="CromologyColorPicker-ProgressBarIcon CromologyColorPicker-ProgressBarIcon--active">
              <svg fill="none" height="6" viewBox="0 0 8 6" width="8" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 1 3.4 5 1 2.333"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div class="CromologyColorPicker-TitleText">Choisir une famille de couleurs</div>
          </div>
          <div class="CromologyColorPicker-PickerBlockContent">
            <div v-if="loading" class="CromologyColorPicker-LoaderCircle"></div>
            <template v-for="(color, index) in colorBullets">
              <div
                v-if="color.displayed"
                :key="color.color_sku_tint_id"
                :style="{
                  'background-color': '#' + color.color_rgb,
                  border: '1px solid ' + (color.color_rgb === 'FFFFFF' ? '#DADADA' : '#' + color.color_rgb)
                }"
                class="CromologyColorPicker-RoundColor"
                @click="selectColorBullets(index)"
              >
                <div v-if="color.selected" class="CromologyColorPicker-RoundColorSelected"></div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="showColorPicker"
          :class="{ 'CromologyColorPicker-PickerBlock--active': step > 1 }"
          class="CromologyColorPicker-PickerBlock"
        >
          <div class="CromologyColorPicker-PickerBlockTitle">
            <div
              :class="{ 'CromologyColorPicker-ProgressBarIcon--active': step > 0 }"
              class="CromologyColorPicker-ProgressBarIcon"
            >
              <svg fill="none" height="6" viewBox="0 0 8 6" width="8" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 1 3.4 5 1 2.333"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div class="CromologyColorPicker-TitleText">Choisir votre couleur</div>
          </div>
          <div class="CromologyColorPicker-PickerBlockContent CromologyColorPicker-PickerBlockContent--rectangles">
            <div
              v-for="(color, index) in colorPanel"
              :key="color.id"
              :style="{
                'background-color': '#' + color.rgb,
                border: '1px solid ' + (color.rgb === 'FFFFFF' ? '#DADADA' : '#' + color.rgb)
              }"
              class="CromologyColorPicker-RectangleColor"
              @click="selectColorPanel(index)"
            >
              <div v-if="color.selected" class="CromologyColorPicker-RectangleColorSelected">
                <svg fill="none" height="12" viewBox="0 0 8 6" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 1 3.4 5 1 2.333"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="colorPanel && colorPanel.length >= 30" class="CromologyColorPicker-UnderlinedButton">
            <button class="sf-button sf-button__secondary CromologyColorPicker-Button" @click="seeMoreColors">
              Voir plus de couleurs
            </button>
          </div>
        </div>
        <div class="CromologyColorPicker-PickerBlock CromologyColorPicker-PickerBlock--noLine">
          <div class="CromologyColorPicker-PickerBlockTitle">
            <div
              :class="{ 'CromologyColorPicker-ProgressBarIcon--active': step > 1 || !showColorPicker }"
              class="CromologyColorPicker-ProgressBarIcon"
            >
              <svg fill="none" height="6" viewBox="0 0 8 6" width="8" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 1 3.4 5 1 2.333"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div class="CromologyColorPicker-TitleText CromologyColorPicker-TitleTextSelectedColor">
              Votre sélection
            </div>
          </div>
          <div
            v-if="selectedColor"
            class="CromologyColorPicker-PickerBlockContent CromologyColorPicker-SelectedColorBlock CromologyColorPicker-SelectedColorBlock--Desktop"
          >
            <div
              :style="{ 'background-color': '#' + selectedColor.rgb }"
              class="CromologyColorPicker-SquareColor"
            ></div>
            <div class="CromologyColorPicker-SelectedColorInfos">
              <div class="CromologyColorPicker-SelectedColorTitle">
                <span>{{ selectedColor.lib_teinte }}</span>
                <svg
                  fill="none"
                  height="16"
                  viewBox="0 0 22 22"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="unselectColor"
                >
                  <path
                    d="M21 20.775 1 1M21 1 1 20.775"
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div>{{ selectedColor.lib_nuancier }}</div>
              <div v-if="selectedColor['ACHAT_TESTEUR']" class="CromologyColorPicker-TestingAvailable">
                Cette couleur est disponible en testeur
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="CromologyColorPicker-Actions">
        <div
          v-if="selectedColor"
          class="CromologyColorPicker-PickerBlockContent CromologyColorPicker-SelectedColorBlock CromologyColorPicker-SelectedColorBlock--Mobile"
        >
          <div :style="{ 'background-color': '#' + selectedColor.rgb }" class="CromologyColorPicker-SquareColor"></div>
          <div class="CromologyColorPicker-SelectedColorInfos">
            <div class="CromologyColorPicker-SelectedColorTitle">
              <span>{{ selectedColor.lib_teinte }}</span>
              <svg
                fill="none"
                height="16"
                viewBox="0 0 22 22"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                @click="unselectColor"
              >
                <path
                  d="M21 20.775 1 1M21 1 1 20.775"
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div>{{ selectedColor.lib_nuancier }}</div>
            <div v-if="selectedColor['ACHAT_TESTEUR']" class="CromologyColorPicker-TestingAvailable">
              Cette couleur est disponible en testeur
            </div>
          </div>
        </div>
        <button class="Button--tollensgreenrevert CromologyColorPicker-Button sf-button sf-button__secondary" @click="close">Annuler</button>
        <button class="Button--action CromologyColorPicker-Button sf-button sf-button__primary" @click="confirm">Choisir cette couleur</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useContext } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';

export default {
  name: 'CromologyColorPicker',
  props: {
    company: {
      type: String,
      required: false
    },
    open: {
      type: Boolean,
      required: true
    },
    /*provider: {
      required: true
    },*/
    articleIds: {
      type: Array,
      required: true
    },
    profile: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      colorBullets: [
        {
          color_rgb: 'f1f0e9',
          color_field: 'color_field_5',
          displayed: false,
          selected: false
        },
        {
          color_rgb: 'b3844e',
          color_field: 'color_field_2',
          displayed: false,
          selected: false
        },
        {
          color_rgb: '0091c9',
          color_field: 'color_field_7',
          displayed: false,
          selected: false
        },
        {
          color_rgb: '60c659',
          color_field: 'color_field_4',
          displayed: false,
          selected: false
        },
        {
          color_rgb: 'fce016',
          color_field: 'color_field_6',
          displayed: false,
          selected: false
        },
        {
          color_rgb: 'ef2b2d',
          color_field: 'color_field_3',
          displayed: false,
          selected: false
        },
        {
          color_rgb: '8977ba',
          color_field: 'color_field_1',
          displayed: false,
          selected: false
        }
      ],
      colorBulletsList: null,
      search: '',
      searchList: [],
      selectedColor: null,
      otherColors: null,
      showColorPicker: true,
      colorPanel: [],
      step: 0,
      colorPanelSize: 30,
      colorPanelIds: [],
      loading: false
    }
  },
  watch: {
    search: debounce(async function (newVal) {
      if (newVal.length >= 3) {
        await this.fetchSearchList(newVal)
      } else {
        this.searchList = []
      }
    }, 300)
  },
  async created() {
    await this.fetchColorBullets()
  },
  setup() {
    const { app }   = useContext();
    const cromology = app.context.$vsf.$cromology;

    return {
      cromology,
    }
  },
  methods: {
    close() {
      this.resetAll()
      this.step = 0
      this.$emit('Cromology::ColorPicker::Close')
    },
    confirm() {
      if (!this.selectedColor) {
        return
      }

      const finalSelectedColor = JSON.parse(JSON.stringify(this.selectedColor))
      delete finalSelectedColor.sameColors

      // For the client, to see the OUT of the shared-component
      /* eslint-disable-no-console */
      console.log([finalSelectedColor, ...this.otherColors])

      this.$emit('Cromology::ColorPicker::ColorSelected', [finalSelectedColor, ...this.otherColors])
      this.$emit('Cromology::ColorPicker::Close')
      this.resetAll()
      this.step = 0
    },
    async fetchColorBullets() {
      this.loading = true

      try {
        const response = await this.cromology.api.colorBullets({ articleIds: this.articleIds });
        if (response && response.data && response.data.colorBullets) {
          this.colorBulletsList = response.data.colorBullets.colors
          response.data.colorBullets.colorBulletsFields.forEach((field) => {
            const bullet = this.colorBullets.find((color) => color.color_field === field)

            if (bullet) {
              bullet.displayed = true
            }
          })
        } else {
          this.resetColorBullets()
        }
      } catch (error) {
        this.resetColorBullets()
        console.log(error)
      }

      this.loading = false
    },
    async fetchColorPanel() {
      try {
        const response = await this.cromology.api.colorimetriesPanel({
          articleIds: this.articleIds,
          colorSkuTintId: this.colorPanelIds,
          from: 0,
          size: this.colorPanelSize
        });
        if (response && response.data && response.data.colorimetriesPanel) {
          this.colorPanel = response.data.colorimetriesPanel
        } else {
          this.colorPanel = []
        }
      } catch (error) {
        this.colorPanel = []
        console.log(error)
      }
    },
    async fetchSearchList(search) {
      try {
        const response = await this.cromology.api.colorimetries({
          articleIds: this.articleIds,
          profile: this.profile,
          stringSearch: search
        });
        if (response && response.data && response.data.colorimetries) {
          this.searchList = response.data.colorimetries
        } else {
          this.searchList = []
        }
      } catch (error) {
        this.searchList = []
        console.log(error)
      }
    },
    resetAll() {
      this.step            = 0
      this.showColorPicker = true
      this.search          = ''
      this.searchList      = []

      this.unselectColor()
    },
    resetColorBullets() {
      this.colorBullets.forEach((color) => {
        color.displayed = false
        color.selected  = false
      })
      this.colorBulletsList = null
    },
    async seeMoreColors() {
      this.colorPanelSize += 30
      await this.fetchColorPanel()
    },
    async selectColorBullets(index) {
      this.selectedColor = null
      this.colorBullets.forEach((color) => {
        color.selected = false
      })
      this.colorBullets[index].selected = true
      this.$set(this.colorBullets, index, this.colorBullets[index])
      this.step = 1

      this.colorPanelSize = 30

      this.colorPanelIds = []
      this.colorBulletsList.forEach((color) => {
        if (color.color_field === this.colorBullets[index].color_field) {
          this.colorPanelIds.push(color.color_sku_tint_id)
        }
      })

      await this.fetchColorPanel()
    },
    async selectSearchColor(event, index) {
      event.stopPropagation()
      this.showColorPicker = false

      await this.selectFinalColor(this.searchList[index].id)

      this.searchList = []
      this.step       = 0
    },
    async selectColorPanel(index) {
      this.colorPanel.forEach((color) => {
        color.selected = false
      })
      this.colorPanel[index].selected = true
      this.$set(this.colorPanel, index, this.colorPanel[index])
      this.step = 2
      await this.selectFinalColor(this.colorPanel[index].id)
    },
    async selectFinalColor(id) {
      try {
        const response = await this.cromology.api.colorimetry({ id });
        if (response && response.data && response.data.colorimetry) {
          this.otherColors   = JSON.parse(JSON.stringify(response.data.colorimetry.sameColors))
          this.selectedColor = response.data.colorimetry
        } else {
          this.selectedColor = null
        }
      } catch (error) {
        this.selectedColor = null
        console.log(error)
      }
    },
    unselectColor() {
      this.selectedColor   = null
      this.showColorPicker = true

      if (this.colorPanel.length > 0) {
        this.colorPanel.forEach((color) => {
          color.selected = null
        })
        this.step = 1
      } else {
        this.step = 0
      }
    },
    fixVH() {
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  },
  mounted() {
    this.fixVH()
  }
}
</script>

<style lang="scss" scoped>
// ColorPicker CSS component

*,
::before,
::after {
  box-sizing: border-box;
}

// Hurme Geometric
/*@font-face {
  font-family: 'hurmegeometric';
  font-style: normal;
  font-weight: 300;
  src: local('hurmegeometric'), url('../../../public/fonts/hurmegeometric/light.woff2') format('woff2');
}

@font-face {
  font-family: 'hurmegeometric';
  font-style: normal;
  font-weight: 500;
  src: local('hurmegeometric'), url('../../../public/fonts/hurmegeometric/medium.woff2') format('woff2');
}

@font-face {
  font-family: 'hurmegeometric';
  font-style: normal;
  font-weight: 700;
  src: local('hurmegeometric'), url('../../../public/fonts/hurmegeometric/bold.woff2') format('woff2');
}*/

// Existing classes

//Rectangular button green
.Button--action {
  padding: 15px 35px;
  /*border: 1px solid;
  color: #ffffff;
  transition: all ease 0.3s;
  background-color: #13595f;
  cursor: pointer;

  &:hover {
    color: #13595f;
    border: 1px solid #13595f;
    background-color: #ffffff;
  }*/
}

//inverted button green
/*.Button--tollensgreenrevert {
  @extend .Button--action;
  color: #13595f;
  border-color: #13595f;
  background-color: #ffffff;

  &:hover {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #13595f;
  }
}*/

// Button override
.CromologyColorPicker-Button {
  height: 45px;
  width: 230px;
  padding: 0;
  font-size: 14px;
}

// New classes
.CromologyColorPicker-ContainerOverlay {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111111;
  opacity: 0.3;
  transition: 0.6s;
}

.CromologyColorPicker-Container {
  z-index: 100;
  position: fixed;
  right: 0;
  top: 87px;
  bottom: 0;
  width: 680px;
  overflow-y: auto;
  font-family: var(--font-family--secondary);
  background-color: #fff;
  transform: translateX(100%);
  transition: 0.6s;

  &--open {
    display: flex;
    flex-direction: column;
    transform: translateX(0);
  }

	@include for-desktop {
		top: 0;
	}
}

.CromologyColorPicker-Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding: 23px 73px 33px 73px;
  font-size: 16px;
  background-color: #f6f6f4;

  .CromologyColorPicker-HeaderTitle {
    font-weight: 600;
  }

  .CromologyColorPicker-Search {
    display: flex;
    justify-content: center;
    width: 100%;

    .CromologyColorPicker-SearchInput {
      width: 100%;
      padding: 12px;
      padding-left: 55px;
      font-size: 14px;
      border: none;
      background-color: #ffffff;
    }

    .CromologyColorPicker-SearchIcon {
      position: absolute;
      left: 90px;
      top: 67px;
    }

    .CromologyColorPicker-SearchList {
      z-index: 9999;
      position: absolute;
      height: 160px;
      width: calc(100% - 146px);
      margin-top: 42px;
      font-size: 14px;
      background-color: #ffffff;
      overflow-y: scroll;
      box-shadow: 0px 3px 4px 1px #f6f6f4;

      .CromologyColorPicker-SearchListItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 55px;
        padding-right: 10px;
        cursor: pointer;
        font-weight: 400;

        &:hover {
          background-color: #f6f6f4;
        }
      }

      .CromologyColorPicker-ItemColor {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        border-radius: 50%;
      }

      span {
        width: calc(100% - 60px);
      }
    }
  }
}

.CromologyColorPicker-OrCircle {
  position: absolute;
  top: 110px;
  left: calc(50% - 21px);
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 50%;
  background-color: #ffffff;
}

.CromologyColorPicker-Picker {
  padding: 42px 73px 42px 73px;
  overflow-x: hidden;

  .CromologyColorPicker-PickerBlock {
    width: 100%;
    padding: 0 18px 30px;
    border-left: 2px solid #13595f80;

    &--active {
      border-left: 2px solid #13595f;
    }

    &--noLine {
      border: none;
    }
  }

  .CromologyColorPicker-PickerBlockTitle {
    display: flex;
    justify-content: flex-start;

    .CromologyColorPicker-ProgressBarIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      background-color: #ffffff;
      border: 1px solid #13595f;
      margin-left: -26px;
      margin-right: 26px;

      &--active {
        background-color: #13595f;
        border: 1px solid #13595f;
      }
    }

    .CromologyColorPicker-TitleText {
      padding-bottom: 21px;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .CromologyColorPicker-PickerBlockContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 26px;

    .CromologyColorPicker-RoundColor {
      width: 35px;
      height: 35px;
      margin-right: 13px;
      border-radius: 50%;
      cursor: pointer;
    }

    .CromologyColorPicker-RoundColorSelected {
      width: 20px;
      margin-top: 46px;
      margin-left: 7.5px;
      border-bottom: 2px solid #000000;
    }

    .CromologyColorPicker-RectangleColor {
      width: 73px;
      height: 40px;
      margin: 2px;
      cursor: pointer;
    }

    .CromologyColorPicker-SquareColor {
      width: 163px;
      height: 163px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .CromologyColorPicker-SelectedColorInfos {
      width: 100%;
      padding: 0 44px 19px 19px;
      padding-top: 0;
      padding-right: 44px;
      font-size: 14px;

      .CromologyColorPicker-SelectedColorTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          font-weight: 600;
        }

        svg {
          cursor: pointer;
        }
      }
    }

    .CromologyColorPicker-RectangleColorSelected {
      text-align: center;

      svg {
        margin-top: 15px;
      }
    }

    &--rectangles {
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .CromologyColorPicker-SelectedColorBlock {
    flex-wrap: nowrap;
    background-color: #ffffff;

    &--Desktop {
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  .CromologyColorPicker-TestingAvailable {
    width: auto;
    margin-top: 17px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    background-color: #d7d0c533;
  }
}

.CromologyColorPicker-Actions {
	margin-top: auto;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px 73px;

	@media only screen and (min-width: 1025px) {
		flex-direction: initial;
		justify-content: space-evenly;
	}

	@media only screen and (max-width: 1024px) {
		padding: 0;
		margin: auto auto 0 auto;
		width: calc(100% - 2.5rem) !important;

		.CromologyColorPicker-SquareColor {
			width: 91px;
			height: 91px;
			margin-left: 14px;
		}

		.sf-button__primary {
			margin-bottom: 0.75rem;
		}
	}

  .CromologyColorPicker-SquareColor {
    width: 163px;
    height: 163px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .CromologyColorPicker-SelectedColorInfos {
    width: 100%;
    padding: 0 44px 19px 19px;
    font-size: 14px;

    .CromologyColorPicker-SelectedColorTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        font-weight: 600;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .CromologyColorPicker-PickerBlockContent {
    display: flex;
    width: 100%;
    padding-bottom: 26px;

    .CromologyColorPicker-RoundColor {
      width: 35px;
      height: 35px;
      margin-right: 13px;
      border-radius: 50%;
      cursor: pointer;
    }

    .CromologyColorPicker-RoundColorSelected {
      width: 20px;
      margin-top: 46px;
      margin-left: 7.5px;
      border-bottom: 2px solid #000000;
    }

    .CromologyColorPicker-RectangleColor {
      width: 73px;
      height: 40px;
      margin: 2px;
      cursor: pointer;
    }

    .CromologyColorPicker-RectangleColorSelected {
      text-align: center;

      svg {
        margin-top: 15px;
      }
    }

    &--rectangles {
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .CromologyColorPicker-TestingAvailable {
    width: auto;
    margin-top: 17px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    background-color: #d7d0c533;
  }

  .CromologyColorPicker-SelectedColorBlock--Mobile {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
}

.CromologyColorPicker-UnderlinedButton {
  width: 100%;
  text-align: center;
  flex-basis: 100%;
  margin-top: 13px;

  button {
    padding: 0;
    background-color: transparent;
    font-size: 14px;
    cursor: pointer;

    &:not(.sf-button__secondary) {
      border: none;
    }
  }
}

.CromologyColorPicker-LoaderCircle {
  width: 35px;
  height: 35px;
  margin-right: 13px;
  border: 3px solid #ffffff;
  border-top: 3px solid #13595f;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .CromologyColorPicker-Container {
    width: 100%;
  }

  .CromologyColorPicker-Header {
    padding: 18px 20px;

    .CromologyColorPicker-Search {
      position: relative;

      .CromologyColorPicker-SearchIcon {
        left: 25px;
        top: 50%;
        height: 18px;
        transform: translateY(-50%);
      }

      .CromologyColorPicker-SearchList {
        width: calc(100% - 40px);

        .CromologyColorPicker-SearchListItem {
          padding: 10px 20px;
        }
      }
    }
  }

  .CromologyColorPicker-OrCircle {
    top: 105px;
  }

  .CromologyColorPicker-Picker {
    padding: 0 20px 129px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .CromologyColorPicker-PickerBlock {
      border: none;
      padding: 0;
    }

    .CromologyColorPicker-PickerBlockTitle {
      .CromologyColorPicker-ProgressBarIcon {
        display: none;
      }

      .CromologyColorPicker-TitleText {
        font-size: 16px;
      }
    }

    .CromologyColorPicker-PickerBlockContent {
      z-index: 3;
      padding-bottom: 30px;
      margin-bottom: 45px;
			flex-wrap: nowrap;
      overflow-x: scroll;
      background-color: #ffffff;

      .CromologyColorPicker-RoundColor {
        flex-grow: 0;
        flex-shrink: 0;
      }

      .CromologyColorPicker-RectangleColor {
        width: 50px;
      }
    }

    .CromologyColorPicker-PickerBlockContent--rectangles {
      flex-wrap: wrap;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .CromologyColorPicker-SelectedColorInfos {
      max-width: calc(100% - 125px);
      padding: 0 0 10px 10px;
      font-size: 12px;
    }
  }

  .CromologyColorPicker-TitleTextSelectedColor {
    display: none;
  }

  .CromologyColorPicker-SelectedColorBlock {
    padding-top: 20px;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
  }

  .CromologyColorPicker-Button {
    width: 100%;
  }
}

@import "@/modules/catalog/product/styles/color-picker.scss";
</style>
