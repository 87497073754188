


















import {defineComponent} from "@nuxtjs/composition-api";
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
	name:"ProductMobileLinks",
	components: {
		SfButton
	},
	props: {
		documentsCount: {
			type: Number,
			default: 0
		},
	}
})
