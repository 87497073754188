

















































































import { SfAccordion, SfButton, SfDivider, SfQuantitySelector } from '@storefront-ui/vue';
import { defineComponent, ref, } from '@nuxtjs/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import wallQtyCalculator from '~/helpers/utils/qtyCalculator/wallQtyCalculator';
import ContentBlocks from '~/components/ContentBlocks.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import PictimeCalculatePeintureInfosModal from '~/components/molecules/PictimeCalculatePeintureInfosModal.vue';
import useUiState from '@/composables/useUiState';

extend('required', {
  ...required,
  message: 'Ce champ est requis',
});

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'Ce champ doit comporter uniquement des nombres ou valeurs décimales'
})

export default defineComponent({
  name: 'WallQtyCalculator',
  components: {
    SfAccordion,
    SfButton,
    SfDivider,
    SfQuantitySelector,
    ValidationProvider,
    ValidationObserver,
    ContentBlocks,
    SvgImage,
    PictimeCalculatePeintureInfosModal
  },
  props: {
    product: {
      type: [Object, null],
      default: null,
    }
  },
  setup(props) {
    const { toggleInfosCalculatePeintureModal, isInfosCalculatePeintureModalOpen } = useUiState();

    let checkedSurface = ref(false);
    let checkedSuperficie = ref(false);
    const showCalculSuperficie = ref(false);
    const showCalculSurface = ref(false);

    const surface = ref('');
    const wallWidth = ref('');
    const wallHeight = ref('');
    const margeChecked = ref(true);
    const superficieRes = ref(0);
    const surfaceRes = ref({});

    const calculateSurface = () => {
      surfaceRes.value = wallQtyCalculator(
        parseFloat(wallWidth.value),
        parseFloat(wallHeight.value),
        parseFloat(props.product.match_height),
        parseFloat(props.product.roll_width),
        parseFloat(props.product.roll_length),
        margeChecked.value
      );
      showCalculSurface.value = true;
    }

    const handleInfosModal = () => {
      toggleInfosCalculatePeintureModal();
    }

    return {
      checkedSurface,
      checkedSuperficie,
      surface,
      wallWidth,
      wallHeight,
      margeChecked,
      calculateSurface,
      superficieRes,
      surfaceRes,
      showCalculSuperficie,
      showCalculSurface,
      handleInfosModal,
      isInfosCalculatePeintureModalOpen
    }
  }
})
