<template>
	<div class="step__content">
		<div class="configuration__chooser configuration__chooser-pmo">
			<div
					:class="(selectorPmoIsOpen) ? 'configuration__chooser-pmo-select-chevron-open' : 'configuration__chooser-pmo-select-chevron'"
					class="configuration__chooser-pmo-select"
					@click="$emit('toggleSelectorPmo')"
			>
				{{ $t(selectedPmoLabel) }}
				<SvgImage
						height="7"
						icon="Icon_chevron"
						width="14"
				/>
			</div>

			<div v-if="selectorPmoIsOpen" class="configuration__chooser-pmo-select-items">
				<div
						v-for="(pmoConfiguration, index) in configurationList"
						:key="pmoConfiguration.uid"
						v-if="selectedConfiguration != pmoConfiguration.uid"
						class="configuration__chooser-pmo-select-items-item"
						@click="updatePmoProductConfiguration(configurableOptions[0].attribute_uid, pmoConfiguration.uid, pmoConfiguration.sku, qty)"
				>
					{{ pmoConfiguration.label }}
				</div>
			</div>
		</div>
	</div></template>
<script>
import SvgImage from "~/components/General/SvgImage.vue";

export default {
	name: 'PMOConfigurationStep',
	components: { SvgImage },
	props: {
		selectorPmoIsOpen: {
			type: Boolean,
			default: false
		},
		selectedPmoLabel: {
			type: String,
			default: ''
		},
		configurationList: {
			type: Array,
			default: () => []
		},
		configurableOptions: {
			type: Array,
			default: () => []
		},
		selectedConfiguration: {
			type: String,
			default: ''
		},
		qty: {
			type: Number,
			default: 0
		}
	},

	setup(props, { emit }) {
		function updatePmoProductConfiguration(attribute_uid, pmoConfigurationId, pmoConfigurationSku, qty) {
			emit('updatePmoProductConfiguration', attribute_uid, pmoConfigurationId, pmoConfigurationSku, qty)
		}

		return {
			updatePmoProductConfiguration
		}
	}
}
</script>

<style>
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>