<template>
	<div v-if="product.stocks !== null">
		<div
				v-if="(isAuth || currentShop) || product.stocks && !(calculStock(product.stocks[0], qty, product.active).store.isOk)"
				class="product__data__stock"
		>
			<PictimeStockExtended
					:currentShop="currentShop"
					:click-and-collect="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).store.isOk"
					:store-label="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).store.label"
					:livraison="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).delivery.isOk"
					:delivery-label="product.stocks && product.stocks[0] && calculStock(product.stocks[0], qty, product.active).delivery.label"
			/>
		</div>
	</div>
	<div v-else>
		<div class="product__data__stock">
			<PictimeStockExtended
					:currentShop="currentShop"
					:click-and-collect="false"
					:livraison="false"
			/>
		</div>
	</div>
</template>
<script>
import PictimeStockExtended from "~/components/molecules/PictimeStockExtended.vue";
import calculStock from '~/helpers/utils/calculStock';
import { defineComponent } from '@nuxtjs/composition-api';


export default defineComponent({
	name: 'ProductStocks',
	components: { PictimeStockExtended },
	props: {
		product: {
			type: Object,
			default: {}
		},
		isAuth: {
			type: Boolean,
			default: false
		},
		currentShop: {
			type: String,
			default: ''
		},
		qty: {
			type: Number,
			default: 0
		}
	},

	setup() {
		return {
			calculStock,
		}
	}
})
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>