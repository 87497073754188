<template>
	<div class="step__content">
		<div v-if="availableConditioningList.length > 0" class="conditioning__chooser">
			<SfButton
					v-for="(availableConditioning) in availableConditioningList"
					:key="availableConditioning['id']"
					:class="{selected: selectedConditioning === availableConditioning['id'], opacity: isConditioningAvailable(availableConditioning['id'])}"
					:disabled="isConditioningAvailable(availableConditioning['id'])"
					class="conditioning__chooser__button"
					@click="$emit('manageConditioning', (availableConditioning['id']))"
			>
				{{ availableConditioning['label'] }}
			</SfButton>
		</div>
	</div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default {
	name: 'ConditionSelectionStep',
	components: {
		SvgImage,
		SfButton
	},
	props: {
		productSku: {
			type: String,
			default: ''
		},
		availableConditioningList: {
			type: Array,
			default: () => []
		},
		selectedConditioning: {
			type: Number,
			default: 0
		},
		configurationData: {
			type: Object,
			default: () => {}
		},
		selectedColor: {
			type: Number,
			default: 0
		},
		isCustom: {
			type: Boolean,
			default: false
		},
		variantData: {
			type: Array,
			default: () => []
		},
		customVariantData: {
			type: Array,
			default: () => []
		},
		customColorCode: {
			type: Array,
			default: () => []
		},
	},
	setup(props) {

		function isConditioningAvailable(conditioningId) {
			if (props.productSku === 'CR_TESTEUR') {
				return false;
			}

			let resultData = props.configurationData;
			let found = false;
			let disableConditionning = false;

			if (resultData && props.selectedColor && !props.isCustom) {
				props.variantData && Object.keys(props.variantData).forEach((value) => {
					if (props.variantData[value].colorId === props.selectedColor && props.variantData[value].conditioningId === conditioningId) {
						found = true;

						return disableConditionning;
					}
				});
			} else {
				Object.keys(props.customVariantData).forEach((value) => {
					Object.keys(props.customColorCode).forEach((color) => {
						if (props.customVariantData[value].conditioningId === conditioningId && props.customColorCode[color] === props.customVariantData[value].codeArticle) {
							found = true;

							return disableConditionning;
						}
					});
				});
			}
			if (!found) {
				disableConditionning = true;
			}

			return disableConditionning;
		}

		return {
			isConditioningAvailable
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";

.opacity {
	background-color: transparent;
}
</style>