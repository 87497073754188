<template>
  <div class="product__add-to-cart">
    <slot name="add-to-cart-btn">
      <SfButton
        :disabled="isProductDisabled || isDisabled"
        class="product__add-to-cart__button sf-button__primary"
        v-on="$listeners"
      >
        <SfLoader :loading="loading" class="add-to-cart__btn sf-button--full-width sf-button__primary">
          <div>{{ $t('Add to cart') }}</div>
        </SfLoader>
      </SfButton>
    </slot>
  </div>
</template>
<script>
import { SfButton, SfPrice, SfLoader } from '@storefront-ui/vue';
import useCart from "~/modules/checkout/composables/useCart";
import { computed } from "@nuxtjs/composition-api";

export default {
  name: "PictimeAddToCart",
  components: {
    SfButton,
    SfPrice,
    SfLoader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    packagingRegularPrice: {
      type: [Number, String],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    clickAndCollect: {
      type: Boolean,
      default: false,
    },
    livraison: {
      type: Boolean,
      default: false,
    },
    currentShop: {
      type: String,
      default: ""
    },
    selectedConditioningLabel: {
      type: String,
      default: ""
    },
    product: {
      type: Object,
      default: () => {}
    },
  },
  setup(props) {
    const isProductDisabled = computed(() => {
      return props.product.active === false;
    });

    const isDisabled = computed(() => {
      return (!props.clickAndCollect && !props.livraison) ||
          props.disabled || !props.currentShop || !props.regularPrice || !props.packagingRegularPrice || !props.selectedConditioningLabel
    });

    const { loading } = useCart()

    return {
      isProductDisabled,
      isDisabled,
      loading
    }
  }
};
</script>
<style lang="scss">
.product__add-to-cart {
  &__button {
    width: 230px;
    @include for-mobile {
      width: 200px;
    }

    &:hover {
      background: var(--c-button-background-hover);
    }
  }
}
</style>
