




























































































































































import LazyHydrate from 'vue-lazy-hydration';
import { SfButton } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRef,
  useContext,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api';

import {
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';

import { getAverageRating, getTotalReviews, } from '~/modules/review/getters/reviewGetters';

import SvgImage from '~/components/General/SvgImage.vue';
import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';
import { Product } from '~/modules/catalog/product/types';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { TabsConfig, useProductTabs } from '~/modules/catalog/product/composables/useProductTabs';
import CromologyColorPicker from '~/modules/catalog/product/components/CromologyColorPicker';
import PictimeProductSheet from '../../../../../../components/organisms/PictimeProductSheet.vue';
import PictimeFlap from '../../../../../../components/molecules/PictimeFlap';
import PictimePrice from '../../../../../../components/molecules/PictimePrice';
import PictimeStockExtended from '../../../../../../components/molecules/PictimeStockExtended';
import PictimeQuantitySelector from '../../../../../../components/molecules/PictimeQuantitySelector';
import PriceLoader from '../../../../../../components/atoms/PriceLoader';
import PendingApprovalModal from '~/components/molecules/PendingApprovalModal.vue';
import { cacheQuote, cacheRole } from '~/helpers/customer/role.ts';
import { useCart, useUiState, useUser } from '~/composables';
import { debounce } from 'lodash-es';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import { useCancelRequestForApproval } from '~/modules/checkout/composables/useCancelRequestForApproval';
import AddToWishlist from '~/modules/wishlist/components/AddToWishlist';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import ProductMobileLinks from "~/modules/catalog/product/components/ProductMobileLinks.vue";
import StepTitle from "~/modules/catalog/product/components/StepTitle.vue";
import ColorSelectionStep from "~/modules/catalog/product/components/ColorSelectionStep.vue";
import ConditionSelectionStep from "~/modules/catalog/product/components/ConditionSelectionStep.vue";
import PMOConfigurationStep from "~/modules/catalog/product/components/PMOConfigurationStep.vue";
import ProductStocks from "~/modules/catalog/product/components/ProductStocks.vue";
import ProductPrices from "~/modules/catalog/product/components/ProductPrices.vue";
import ProductAddToCart from "~/modules/catalog/product/components/ProductAddToCart.vue";

export default defineComponent({
  name: 'ConfigurableProduct',
  components: {
		ProductAddToCart,
		ProductPrices,
		ProductStocks,
		PMOConfigurationStep,
		ConditionSelectionStep,
		ColorSelectionStep,
		StepTitle,
		ProductMobileLinks,
    AddToWishlist,
    LazyHydrate,
    SfButton,
    SvgImage,
    ProductTabs,
    PictimeProductSheet,
    PictimeFlap,
    PictimePrice,
    PictimeStockExtended,
    PictimeQuantitySelector,
    CromologyColorPicker,
    PriceLoader,
    PendingApprovalModal,
    AddToCartSidebar: () => import(/* webpackPrefetch: true */ '~/components/AddToCartSidebar.vue')
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    productAssets: {
      type: [Array, Object, String],
      default: () => ['']
    },
    productType: {
      type: String,
      default: 'Peintures pro'
    },
    documentsCount: {
      type: Number,
      default: 0
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    priceLoading: {
      type: Boolean,
      default: true
    },
    testerLink: {
      type: String,
      default: '',
    },
    configurableProductConfiguration: {
      type: Object,
      default: () => ({}),
    },
    documents: {
      type: Array,
      default: {}
    }
  },
  data() {
    return {
      openPicker: false,
    }
  },
  setup(props, { emit }) {
    const {
      isAddToCartSidebarOpen,
      toggleAddToCartSidebar,
      toggleAddToWishlistSidebar,
      isPendingModalOpen,
      togglePendingModal,
      isQtyCalculatorSidebarOpen
    } = useUiState();
    const {
      addItem,
      error: cartError,
      loading: isCartLoading,
      load: loadCart,
      canAddToCart,
      cart: cartInstant,
      setCart
    } = useCart();
    const { error: wishlistError } = useWishlist();
    const productStore = useProductStore();
    const customerStore = useCustomerStore();
    const route = useRoute();
    const router = useRouter();
    const { hasApprovalOrder } = cacheQuote();
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();
    const { isAuthenticated, load, user } = useUser();
    const { app } = useContext();

    const currentShop = computed(() => customerStore.getCurrentShopCode)
    const qty = ref(1);
    const variant_sku = ref('');
    const product = toRef(props, 'product');
    const isAuth = ref(false);

    const productConfiguration = ref(route.value.query);
    const optionsConfiguration = ref([]);
    const selectedOptionSku = ref('');
    const productSpecialPrice = computed(() => getConfigurableProductSpecialPriceCommand(props.product));
    const isCustom = ref(false);

    const selectedPmoLabel = ref('Please select an item');
    const selectorPmoIsOpen = ref(false);

    const productShortDescription = computed(() => props.product?.short_description?.html || '');
    const configurableOptions = computed(() => props.product?.configurable_options ?? []);
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const addToWishlistError = computed(() => wishlistError.value?.addItem?.message);

    const configurationData = ref({});
    const articleIds = ref([]);

    const { params: { slug } } = route.value;

    const disableAddToCart = ref(true);

    const selectedColor = ref(0);
    const selectedColorLabel = ref('');
    const selectedConditioning = ref(0);
    const selectedConditioningLabel = ref('');
    const selectedConfiguration = ref('');
    const selectedConfigurationLabel = ref('');
    const showCustomColor = ref(0);
    const baseColorList = ref([]);
    const configurationList = ref([]);
    const availableConditioningList = ref([]);

    const variantData = ref([]);
    const customVariantData = ref([]);
    const customizationData = ref({});

    const customColor = ref(false);
    const customColorResult = ref({})
    const customColorExa = ref('');
    const customColorLabel = ref('');
    const customColorCode = ref([]);
    const customColorIsSelected = ref(false);
    const lastResult = ref([]);
    const unitPriceHue = ref('');

    const isFromPrice = computed(
      () => {
        if (product.value.priceExcludingTax && product.value.priceIncludingTax) {
          const data = isAuth.value ? product.value.priceExcludingTax.isFromPrice : product.value.priceIncludingTax.isFromPrice;
					return selectedConditioning.value ? data : false;
        }
      }
    );

    const { productGallery, imageSizes } = useProductGallery(product);
    const customProductGallery = computed(() => ([{
      mobile: { url: product?.value?.thumbnail?.url },
      desktop: { url: product?.value?.small_image?.url },
      big: { url: product?.value?.image?.url },
      // eslint-disable-next-line no-underscore-dangle
      alt: product.value.name
    }]));

		function toggleColorPicker () {
			this.openPicker = !this.openPicker;
		}

    const getTaxe = (isAuth) => {
      return !isAuth ? ' TTC' : ' HT'
    };

    const getBaseSearchQuery = () => ({
      filter: {
        url_key: {
          eq: slug,
        },
      },
      configurations: Object.entries(productConfiguration.value).map(
        (config) => config[1],
      ) as string[],
    });

    const updateOptionsConfiguration = (label: string, id: string) => {
      optionsConfiguration.value = [
        { id: customizationData.value.label, value_string: label },
        { id: customizationData.value.exa, value_string: id }
      ]
    };

    const updatePmoProductConfiguration = (label: string, value: string, sku: string, quantity: number) => {
      selectorPmoIsOpen.value = false;

      productStore.setProduct({
        product: {
          sku: sku,
          conditionning: value,
          pmoLabel: label,
          pmoQty: qty,
          color: '',
          customColor: {},
          customColorExa: '',
          customColorLabel: '',
        }
      })

      Object.keys(configurationList.value).forEach((item) => {
        if (configurationList.value[item].uid === value) {
          selectedPmoLabel.value = configurationList.value[item].label;
          selectedConfigurationLabel.value = selectedPmoLabel.value;
          selectedConditioningLabel.value = selectedPmoLabel.value;
        }
      });
      selectedConditioning.value = value;
      updateProductConfiguration(label, value, sku, quantity);
    };

    const toggleSelectorPmo = () => {
      selectorPmoIsOpen.value = !selectorPmoIsOpen.value
    };

    const updateProductConfiguration = (label: string, value: string, sku: string, quantity: number) => {
      selectedConfiguration.value = value;
      disableAddToCart.value = false;
      selectedOptionSku.value = sku;
      variant_sku.value = sku;

      if (!isFromPrice.value && productConfiguration.value[label] === value) return;

      productConfiguration.value[label] = value;
      const routeData = router.resolve({
        path: `${app.localePath(window.location.pathname)}`,
        query: {
          ...productConfiguration.value,
        },
      });

      window.history.pushState({}, null, routeData.href);

      emit('fetchProduct', {
        query: getBaseSearchQuery(),
        sku: sku,
        quantity: quantity,
        unitPrice: unitPriceHue.value
      });
    };

    const addItemToCart = async (product, qty, selectedOptionSku, optionsConfiguration, isCustom) => {

      if (isAlreadyApprovalOrder.value) {
        togglePendingModal()

      } else {
        disableAddToCart.value = true;
        await addItem({
          product,
          quantity: parseInt(qty),
          selectedOptionSku,
          optionsConfiguration,
          isCustom,
          pricingUnit: unitPriceHue.value
        });

        if (!addToCartError.value) {
          productStore.setProductModal(
            {
              id: product.sku,
              articleCode: product.articleCode,
              color: customColorIsSelected.value ? customColorLabel.value : selectedColorLabel.value,
              conditioning: selectedConditioningLabel.value,
              configuration: selectedConfigurationLabel.value,
              image: product.image,
              name :product.name,
              quantity: qty,
              taxe: getTaxe(isAuth.value),
              total: isAuth.value ? Math.round((product.priceExcludingTax.packagingFinalPrice * qty) * 100)/100 : Math.round((product.priceIncludingTax.packagingFinalPrice * qty) * 100)/100,
              type: props.productType
            }
          )

          toggleAddToCartSidebar();
          disableAddToCart.value = false;
          clearStoredProduct();
        }
      }
    };

    const getDatas = async () => {
      let codes = [];

      configurationData.value = props.configurableProductConfiguration;
      if (configurationData.value) {
        Object.keys(configurationData.value.items).forEach((item) => {
          codes.push(configurationData.value.items[item].articleCode);
        });
      }

      codes = codes.filter(function (el) {
        return el != null;
      });

      articleIds.value = codes;
    };

    const getPmoData = async () => {
      await getDatas();
      const storeProduct = productStore.getProduct;

      if (configurationData.value) {
        Object.keys(configurableOptions.value[0].values).forEach((value) => {
          Object.keys(configurationData.value.items).forEach((item) => {
            if (configurableOptions.value[0].values[value].label == configurationData.value.items[item].variationNumber && configurationData.value.items[item].name) {
              configurationList.value.push({
                uid: configurableOptions.value[0].values[value].uid,
                label: configurationData.value.items[item].name,
                sku: configurationData.value.items[item].sku
              });
            }
          });
        });

        if (storeProduct?.product?.sku) {
          updatePmoProductConfiguration(storeProduct.product.pmoLabel, storeProduct.product.conditionning, storeProduct.product.sku, storeProduct.product.pmoQty)
          selectedConfiguration.value = storeProduct.product.conditionning;
        } else if (configurableOptions.value[0].attribute_uid && configurationList.value[0]?.uid) {
          updatePmoProductConfiguration(configurableOptions.value[0].attribute_uid, configurationList.value[0].uid, configurationList.value[0].sku, qty.value)
          selectedConfiguration.value = configurationList.value[0].uid;
        }
      }
    };

    const getPaintData = async () => {
      await getDatas();
      if (configurationData.value) {
        getConfigurableProductData(configurationData.value);
        createVariantDataArray(configurationData.value);
        createCustomVariantDataArray(configurationData.value);
        setDefaultVariant(configurationData.value);
        getProductInfoFromStore();
        selectConditionningForTesterProduct();
        authoriseCustomColor(configurationData.value);

				if (availableConditioningList.value.length === 1) {
					const conditionningId = availableConditioningList.value[0].id;
					manageConditioning(conditionningId);
				}
      }
    };

    function getConfigurableProductData(result) {
      let baseColor = [];
      let availableConditioning = [];

      let count = 1;

      //1: ALLOW CUSTOM COLORS
      let custom = result?.allowCustomColor;
      showCustomColor.value = custom

      //2: ADD BASE COLOR TO COLOR LIST (IF RESULT.BASECOLOR IS DEFINED -> BASE COLOR IS AUTHORIZED ON PRODUCT)
      if (result?.baseColor) {
        baseColor.push({ id: count, label: result?.baseColor });
        count++;
      }

      //3: ADD FACTORY COLORS TO COLOR LIST (IF RESULT.FACTORYCOLORS IS DEFINED -> FACTORY COLORS ARE AUTHORIZED ON PRODUCT)
      if (result?.factoryColors) {
        Object.keys(result?.factoryColors).forEach((color) => {
          if (result?.factoryColors[color]) {
            baseColor.push({ id: count, label: result?.factoryColors[color] });
            count++;
          }
        });
      }

      baseColorList.value = baseColor

      //4: SET AVAILABLE CONDITIONING
      count = 1;

      if (result?.availableConditioning) {
        Object.keys(result?.availableConditioning).forEach((conditioning) => {
          availableConditioning.push({ id: count, label: result?.availableConditioning[conditioning] });
          count++;
        });

        availableConditioningList.value = availableConditioning
      }

      //5: SET CUSTOMIZATION DATA
      customizationData.value = { label: result?.customizationColorLabelId, exa: result?.customizationColorLabelExa };
    }

    function setDefaultVariant(result) {
      let color = '';
      let colorId = 0;

      if (result) {
        Object.keys(result?.items).forEach((item) => {
          if (result?.items[item].variationNumber === result?.defaultConfigVariant) {

            //1: SET DEFAULT COLOR ID
            if (result?.items[item].color) {
              color = result?.items[item].color;
            } else {
              color = result?.baseColor;
            }
            Object.keys(baseColorList.value).forEach((base_color) => {
              if (baseColorList.value[base_color].label == color) {
                colorId = baseColorList.value[base_color].id;
              }
            });

            if (colorId) {
              selectedColor.value = colorId

              getBaseColorLabel(colorId);
            }
          }
        });
        if (!selectedColor.value && !result.baseColor && result.factoryColors.length) { //CAS READY MIX (produit teinte usine uniquement)
          colorId = baseColorList?.value[0].id;
          getBaseColorLabel(colorId);
          selectedColor.value = colorId
        }
      }
    }

    function authoriseCustomColor(result) {
      if (result?.allowCustomColors) {
        showCustomColor.value = 1;
      }
    }

    function createVariantDataArray(result) {
      let variantDataTmp = [];

      Object.keys(configurableOptions.value[0].values).forEach((value) => {
        Object.keys(result.items).forEach((item) => {
          if (configurableOptions.value[0].values[value].label == result.items[item].variationNumber && (result.items[item].isBaseColor || result.items[item].isFactoryColor)) {
            let idColor = 1;
            Object.keys(baseColorList.value).forEach((color) => {
              if (result.items[item].color == baseColorList.value[color].label) {
                idColor = baseColorList.value[color].id;
              }
            });
            let idCond = 1;
            Object.keys(availableConditioningList.value).forEach((cond) => {
              if (result.items[item].conditioning == availableConditioningList.value[cond].label) {
                idCond = availableConditioningList.value[cond].id;
              }
            });
            variantDataTmp.push({
              uid: configurableOptions.value[0].values[value].uid,
              colorId: idColor,
              conditioningId: idCond,
              sku: result.items[item].sku
            });
          }
        });
      });

      variantData.value = variantDataTmp;
    }

    function createCustomVariantDataArray(result) {
      let variantDataTmp = [];

      Object.keys(configurableOptions.value[0].values).forEach((value) => {
        Object.keys(result.items).forEach((item) => {
          if (configurableOptions.value[0].values[value].label == result.items[item].variationNumber) {
            let idColor = 1;
            Object.keys(baseColorList.value).forEach((color) => {
              if (result.items[item].color == baseColorList.value[color].label) {
                idColor = baseColorList.value[color].id;
              }
            });
            let idCond = 1;
            Object.keys(availableConditioningList.value).forEach((cond) => {
              if (result.items[item].conditioning == availableConditioningList.value[cond].label) {
                idCond = availableConditioningList.value[cond].id;
              }
            });
            variantDataTmp.push({
              uid: configurableOptions.value[0].values[value].uid,
              colorId: idColor,
              conditioningId: idCond,
              codeArticle: result.items[item].articleCode,
              sku: result.items[item].sku
            });
          }
        });
      });

      customVariantData.value = variantDataTmp;
    }

    function getBaseColorLabel(colorId) {
      selectedColorLabel.value = getBaseColorLabelById(colorId);
    }

    function getBaseColorLabelById(colorId) {
      const color = Object.values(baseColorList.value).find((el) => el.id === colorId)
      return color?.label || '';
    }

    function manageColor(colorId) {
      productStore.setProduct({
        product: {
          sku: product.value.sku,
          conditionning: selectedConditioning.value,
          color: colorId,
          customColor: {},
          customColorExa: '',
          customColorLabel: '',
        }
      })

      getBaseColorLabel(colorId);
      selectedColor.value = colorId;

      // Reinitialise les couleurs customisées et l'unité de tarif teinte
      isCustom.value = false;
      customColorIsSelected.value = false;
      unitPriceHue.value = '';

      // Reinitialise le conditionnement
      selectedConditioning.value = '';
      selectedConditioningLabel.value = '';

      disableAddToCart.value = true;

      // Récupère le conditionnement le plus élevé en 1er
      variantData.value = variantData.value.sort((p1, p2) => parseInt(p1.conditioningId) > parseInt(p2.conditioningId) ? -1 : 1);

      selectConditionningForTesterProduct();

			if (availableConditioningList.value.length === 1) {
				const conditionningId = availableConditioningList.value[0].id;
				manageConditioning(conditionningId);
			}

			let shouldContinue = true;
      if (configurationData.value && selectedColor.value) {
        Object.keys(variantData.value).every((value) => {
          if (variantData.value[value].colorId == colorId) {
            updateProductConfiguration(configurableOptions.value[0].attribute_uid, variantData.value[value].uid, variantData.value[value].sku, qty.value);
            updateOptionsConfiguration('', '');
            shouldContinue = false;
          }
          return shouldContinue;
        });
      }
    }

    function getConditioningLabel(conditioningId) {
      selectedConditioningLabel.value = getLabelConditioningById(conditioningId)
    }

    function getLabelConditioningById(conditioningId) {
      const conditioning = Object.values(availableConditioningList.value).find((el) => el.id === conditioningId)
      return conditioning?.label || ''
    }

    function manageConditioning(conditioningId) {
      productStore.setProduct({
        product: {
          sku: product.value.sku,
          conditionning: conditioningId,
          color: selectedColor.value,
          customColorExa: customColorExa.value,
          customColorLabel: customColorLabel.value,
          customColor: customColorResult.value,
        }
      })

      let resultData = configurationData.value;
      let found = false;
      selectedConditioningLabel.value = '';
      getConditioningLabel(conditioningId);

      selectedConditioning.value = conditioningId;
      if (resultData && selectedColor.value && selectedConditioning.value && !isCustom.value) {
        Object.keys(variantData.value).forEach((value) => {
          if (variantData.value[value].colorId == selectedColor.value && variantData.value[value].conditioningId == conditioningId) {
            found = true;
            updateProductConfiguration(configurableOptions.value[0].attribute_uid, variantData.value[value].uid, variantData.value[value].sku, qty.value);
          }
        });
      } else {
        Object.keys(customVariantData.value).forEach((value) => {
          Object.keys(customColorCode.value).forEach((color) => {
            if (customVariantData.value[value].conditioningId == conditioningId && customColorCode.value[color] == customVariantData.value[value].codeArticle) {
              found = true;
              updateProductConfiguration(configurableOptions.value[0].attribute_uid, customVariantData.value[value].uid, customVariantData.value[value].sku, qty.value);
            }
          });
        });
      }
      if (!found) {
        disableAddToCart.value = true;
      }
    }

    function selectConditionningForTesterProduct() {
      if (product.value.sku === 'CR_TESTEUR') {
        if (availableConditioningList.value.length) {
          const testerConditionningId = availableConditioningList.value[0].id;
          manageConditioning(testerConditionningId);
        }
      }
    }

    function manageCustomColor(result) {
      customColorResult.value = result;
      // Récupère le conditionnement le plus élevé en 1er
      result = result.sort((p1, p2) => parseInt(p1.condi) > parseInt(p2.condi) ? -1 : 1);

      productStore.setProduct({
        product: {
          sku: product.value.sku,
          conditionning: selectedConditioning.value,
          color: '',
          customColor: result,
          customColorExa: result[0].rgb,
          customColorLabel: result[0].lib_teinte,
        }
      })

      customColor.value = true;
      customColorLabel.value = result[0].lib_teinte;
      customColorExa.value = result[0].rgb;
      selectedColor.value = 0;
      customColorIsSelected.value = true;
      selectedConditioning.value = '';
      selectedConditioningLabel.value = '';
      isCustom.value = true;
      lastResult.value = result;

      unitPriceHue.value = result[0].unite_tarif_teinte;

      selectConditionningForTesterProduct();

			if (availableConditioningList.value.length === 1) {
				const conditionningId = availableConditioningList.value[0].id;
				manageConditioning(conditionningId);
			}

      Object.keys(result).forEach((res) => {
        customColorCode.value.push(result[res].code_article);
      });

      Object.keys(configurationData.value.items).forEach((item) => {
        if (configurationData.value.items[item].articleCode == result[0].code_article) {
          Object.keys(customVariantData.value).forEach((value) => {
            if (customVariantData.value[value].codeArticle == result[0].code_article) {
              updateProductConfiguration(
                configurableOptions.value[0].attribute_uid,
                customVariantData.value[value].uid,
                customVariantData.value[value].sku,
                qty.value
              );
              updateOptionsConfiguration(
                result[0].lib_teinte,
                result[0].id_teinte
              );
              variant_sku.value = customVariantData.value[value].sku;
            }
          });
        }
      });
    }

    //Fonction appelée lors de la fermeture du ColorPicker
    function onCloseColorPicker() {
      this.openPicker = false;
      const colorPicker = this.$refs.colorPicker;

      // Reinitialisation famille couleur
      colorPicker.colorBullets.forEach((color) => {
        color.displayed = true;
        color.selected = false;
      });
      // Reinitialisation couleur
      colorPicker.colorPanel = [];
      // Reinitialisation couleur séléctionnée
      colorPicker.selectedColor = null;
      colorPicker.step = 0;
    }


    const sendCancelForApproval = async () => {
      const result = await cancelForApproval({
        cart_id: cartStore.$state.cart?.id
      });
      if (result.data.cancelRequestForApproval.cart_id) {
        togglePendingModal();
        isAlreadyApprovalOrder.value = false;
      }
    };

    function getProductInfoFromStore() {
      const storeProduct = productStore.getProduct;

      if (storeProduct.product && storeProduct.product.sku === product.value.sku) {
        if (props.productType === 'Peintures pro') {
          if (storeProduct.product.customColorExa && storeProduct.product.customColorLabel) {
            manageCustomColor(storeProduct.product.customColor)
          } else {
            manageColor(storeProduct.product.color)
          }

          manageConditioning(storeProduct.product.conditionning);
        }

        if (props.productType === 'PMO') {
          updatePmoProductConfiguration(
            storeProduct.product.pmoLabel,
            storeProduct.product.conditionning,
            storeProduct.product.sku,
            storeProduct.product.pmoQty
          )
        }
      }
    }

    function clearStoredProduct() {
      productStore.setProduct({});
      localStorage.removeItem('productStored');
    }

    /* Partie sous-compte */
    const { isSubAccount } = cacheRole();
    const cartStore = useCartStore();
    const cart = ref(cartStore.$state.cart);
    const { save: cancelForApproval } = useCancelRequestForApproval();
    const firstnameDirigeant = ref('');
    const lastnameDirigeant = ref('');
    const isAlreadyApprovalOrder = ref(false);

    if (isSubAccount.value === true) {
      if (user.value === null) {
        load();
      } else {
        firstnameDirigeant.value = user.value.dirigeant?.firstname;
        lastnameDirigeant.value = user.value.dirigeant?.lastname;
      }
    }

		/* Partie wishlist */
    const wishlistStore = useWishlistStore()
    const wishlistIsLoaded = ref(false)
    let wishlistVariantColor = ''
    let wishlistVariantConditioning = ''
    let wishlistVariantArticleCode = ''

    const wishlistOptionUid = computed(() => {
      if (selectedConfiguration.value) {
        return selectedConfiguration.value
      }
      const colorId = baseColorList.value[0]?.['id']
      const variantColors = Object.values(variantData.value).filter(variant => variant.colorId === colorId)
      const uidDefault = Object.values(variantColors).reduce(
        (a, b) => a.conditioningId > b.conditioningId ? a : b,
        variantColors[0]
      )

      return uidDefault?.uid
    })

    let selectedOptionsUids = [];
    let enteredOptions = [];
    const toggleWishlist = async () => {
      selectedOptionsUids = wishlistOptionUid.value ? [wishlistOptionUid.value] : []

      if (isCustom.value) {
        optionsConfiguration.value.map((data) => selectedOptionsUids.push(
          btoa(`custom-option/${data.id}/${data.value_string}`))
        )
        enteredOptions = [{ uid: 'pricing_unit', value: unitPriceHue.value }]
      }

      const variant = Object.values(variantData.value).find(variant => variant.uid === wishlistOptionUid.value)

      if (isCustom.value) {
        // Si peinture couleur custom
        wishlistVariantConditioning = selectedConditioningLabel.value || productStore.getProduct.product?.customColor[0]?.condi || ''
        wishlistVariantColor = customColorLabel.value
        wishlistVariantArticleCode = variant_sku.value.replace('AQZ_', '')
      } else if (variant) {
        // Sinon peinture couleur de base
        wishlistVariantConditioning = getLabelConditioningById(variant?.conditioningId)
        wishlistVariantColor = getBaseColorLabelById(variant?.colorId)
        wishlistVariantArticleCode = (variant?.sku || '').replace('AQZ_', '')
      } else {
        // Sinon produit PMO
        wishlistVariantArticleCode = product.value.articleCode
      }

      wishlistStore.setProductAdded({
        id: product.value.sku,
        product: product.value,
        selectedOptionsUids: selectedOptionsUids,
        enteredOption: enteredOptions,
        articleCode: wishlistVariantArticleCode,
        color: wishlistVariantColor,
        conditioning: wishlistVariantConditioning,
        type: props.productType
      })

      toggleAddToWishlistSidebar()
    }

		/* Cycle de vie du composant */
		onMounted(async () => {
			isAuth.value = await isAuthenticated();

			if (isSubAccount.value) {
				if (!cart.value.id) {
					await loadCart({ customQuery: { cart: 'customCart' } });
					setCart(cartInstant.value);
					cart.value = cartStore.$state.cart;
				}

				isAlreadyApprovalOrder.value = hasApprovalOrder(cart.value);
			}
		});

		if (props.productType == 'Peintures pro') {
			getPaintData();
		} else if (props.productType == 'PMO') {
			getPmoData();
		}

		onBeforeUnmount(() => {
			clearStoredProduct()
		})

		watch(qty, debounce((qty, prevQty) => {
			emit('fetchProduct', {
				query: getBaseSearchQuery(),
				sku: variant_sku.value,
				quantity: qty,
				unitPrice: unitPriceHue.value
			})
		}, 500));

    return {
      addItem,
      canAddToCart,
      configurableOptions,
      updateProductConfiguration,
      updatePmoProductConfiguration,
      isAuth,
      isCartLoading,
      productConfiguration,
      optionsConfiguration,
      productGallery,
      getProductName,
      getProductPrice,
      productShortDescription,
      productSpecialPrice,
      qty,
      totalReviews,
      averageRating,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      addToCartError,
      addToWishlistError,
      customProductGallery,
      getTaxe,
      articleIds,
      onCloseColorPicker,
      manageColor,
      manageConditioning,
      customColor,
      customColorResult,
      customColorExa,
      customColorLabel,
      customColorCode,
      customColorIsSelected,
      selectedColor,
      selectedColorLabel,
      selectedConditioning,
      selectedConditioningLabel,
      selectedConfiguration,
      selectedConfigurationLabel,
      showCustomColor,
      baseColorList,
      configurationList,
      availableConditioningList,
      disableAddToCart,
      selectedOptionSku,
      manageCustomColor,
      isCustom,
      lastResult,
      watch,
      variant_sku,
      selectedPmoLabel,
      selectorPmoIsOpen,
      toggleSelectorPmo,
      isAddToCartSidebarOpen,
      toggleAddToCartSidebar,
      addItemToCart,
      firstnameDirigeant,
      lastnameDirigeant,
      isPendingModalOpen,
      togglePendingModal,
      isAlreadyApprovalOrder,
      sendCancelForApproval,
      currentShop,
      isQtyCalculatorSidebarOpen,
      unitPriceHue,
      isFromPrice,
      variantData,
			customVariantData,
      wishlistIsLoaded,
      toggleWishlist,
			toggleColorPicker,
			configurationData
    };
  },
});
