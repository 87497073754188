







































































































































































import { SfAccordion, SfButton, SfDivider, SfQuantitySelector } from '@storefront-ui/vue';
import { defineComponent, ref, } from '@nuxtjs/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import paintSurfaceCalculator from '~/helpers/utils/qtyCalculator/paintSurfaceCalculator';
import paintSuperficieCalculator from '~/helpers/utils/qtyCalculator/paintSuperficieCalculator';
import ContentBlocks from '~/components/ContentBlocks.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import PictimeCalculatePeintureInfosModal from '~/components/molecules/PictimeCalculatePeintureInfosModal.vue';
import CustomQuantitySelector from '~/components/molecules/CustomQuantitySelector.vue'
import useUiState from '@/composables/useUiState';

extend('required', {
  ...required,
  message: 'Ce champ est requis',
});

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'Ce champ doit comporter uniquement des nombres ou valeurs décimales'
})

export default defineComponent({
  name: 'PaintQtyCalculator',
  components: {
    SfAccordion,
    SfButton,
    SfDivider,
    SfQuantitySelector,
    CustomQuantitySelector,
    ValidationProvider,
    ValidationObserver,
    ContentBlocks,
    SvgImage,
    PictimeCalculatePeintureInfosModal
  },
	props: {
		packagingMeasureUnit: {
			type: String,
			default: '',
		},
		coverageValue: {
			type: Number,
			default: 1
		}
	},
  setup(props) {
    const { toggleInfosCalculatePeintureModal, isInfosCalculatePeintureModalOpen } = useUiState();

    let checkedSurface = ref(false);
    let checkedSuperficie = ref(false);
    const showCalculSuperficie = ref(false);
    const showCalculSurface = ref(false);

    const surface = ref('');
    const wallWidth = ref('');
    const wallHeight = ref('');
    const margeChecked = ref(true);
    const windowsQty = ref(0);
    const doorsQty = ref(0);
    const layersQty = ref(2);
    const superficieRes = ref(0);
    const surfaceRes = ref(0);

    const calculateSurface = () => {
      surfaceRes.value = paintSurfaceCalculator(surface.value, props.coverageValue, margeChecked.value,);
      showCalculSurface.value = true;
    }
    const calculateSuperficie = () => {
      superficieRes.value = paintSuperficieCalculator(wallWidth.value, wallHeight.value, windowsQty.value, doorsQty.value);
      surfaceRes.value = paintSurfaceCalculator(superficieRes.value, props.coverageValue, margeChecked.value, true, layersQty.value);
      showCalculSuperficie.value = true;
    }

    const handleInfosModal = () => {
      toggleInfosCalculatePeintureModal();
    }

    function changeSurface() {
      checkedSurface.value = true;
      checkedSuperficie.value = false;
    }

    function changeSuperficie() {
      checkedSurface.value = false;
      checkedSuperficie.value = true;
    }

    return {
      checkedSurface,
      checkedSuperficie,
      changeSurface,
      changeSuperficie,
      windowsQty,
      doorsQty,
      layersQty,
      surface,
      wallWidth,
      wallHeight,
      margeChecked,
      calculateSurface,
      calculateSuperficie,
      superficieRes,
      surfaceRes,
      showCalculSuperficie,
      showCalculSurface,
      handleInfosModal,
      isInfosCalculatePeintureModalOpen
    }
  }
})
