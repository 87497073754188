<template>
	<div class="step__title">
			<span class="step__level">{{ stepNumber }}</span>
		<span class="step__separator"/>
		<span class="step__label">{{ $t(stepLabel) }}</span>
	</div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent( {
	name: 'StepTitle',
	props: {
		stepNumber: {
			type: Number,
			default: 1
		},
		stepLabel: {
			type: String,
			default: ''
		}
	}
})
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>