<template>
	<div class="step__content">
		<div class="color__chooser">
			<SfButton
					v-for="(baseColor, index) in baseColorList"
					:key="baseColor['id']"
					:class="(selectedColor == baseColor['id']) ? 'selected' : ''"
					class="color__chooser__button"
					@click="$emit('manageColor', baseColor['id'])"
			>
				{{ baseColor['label'] }}
			</SfButton>

			<SfButton
					v-if="showCustomColor && customColor"
					:class="(customColorIsSelected) ? 'selected' : ''"
					class="color__chooser__button"
					@click="$emit('manageCustomColor', lastResult)"
			>
				<span :style="{'background': '#'+customColorExa}" class="color__chooser__button__custom-exa"></span>
				<span class="color__chooser__button__custom-label">
				{{ customColorLabel }}
			</span>
			</SfButton>
			<SfButton
					v-if="showCustomColor"
					class="color__chooser__button"
					@click="$emit('toggleColorPicker')"
			>
				<SvgImage
						class="color__chooser__button__icon"
						height="20"
						icon="Icon_couleurs"
						width="20"
				/>
				<span class="color__chooser__button__label">
				{{ $t('Other colors') }}
			</span>
			</SfButton>
		</div>
	</div>

</template>
<script>
import SvgImage from "~/components/General/SvgImage.vue";
import { SfButton } from '@storefront-ui/vue';

export default {
	name: 'ColorSelectionStep',
	components: {
		SvgImage,
		SfButton
	},
	props: {
		baseColorList: {
			type: Array,
			default: () => []
		},
		selectedColor: {
			type: Number,
			default: 0
		},
		showCustomColor: {
			type: Number,
			default: 0
		},
		customColor: {
			type: Boolean,
			default: false
		},
		customColorIsSelected: {
			type: Boolean,
			default: false
		},
		customColorExa: {
			type: String,
			default: ''
		},
		customColorLabel: {
			type: String,
			default: ''
		},
		lastResult: {
			type: Array,
			default: () => []
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/pages/product.scss";
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>