





















































































































import { SfButton, SfLoader, SfSection } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, ref, ssrRef, useContext, } from '@nuxtjs/composition-api';
import { useImage, useProductConditions, } from '~/composables';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { SortEnum } from '~/modules/GraphQL/types';
import PictimeProductCard from '~/components/organisms/PictimeProductCard';
import { ProductConditionQuery } from '~/custom-api/api/productConditions/types';
import calculFlap from '~/helpers/utils/calculFlap';
import calculStock from '~/helpers/utils/calculStock';
import { useGetBestSellingProducts } from '~/modules/customer/composables/useGetBestSellingProducts';
import { onSSR } from '@vue-storefront/core';

export default defineComponent({
  name: 'BestSellers',
  components: {
    SfLoader,
    SfSection,
    PictimeProductCard,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'normal', //also existing : personal, personal_extended
    },
  },

  setup(props) {
    const { user, isAuthenticated, load: loadUser } = useUser();
    const { getProductList, loading } = useProduct();
    const { addItemToCart, isInCart } = useAddToCart();
    const { fetchProductConditions } = useProductConditions();

    const { loadProducts: loadUserSellingProducts } = useGetBestSellingProducts();

    const products = ref([]);
    const priceLoading = ref(true);
    const pricesConditions = ref([] as ProductConditionQuery[]);
    const { app } = useContext();
    const totalItems = ref(12);
    const showTotals = props.mode === 'personal_extended';
    const totalPages = ref(1);
    const currentPage = ref(1);
    const itemPerPage = ref(12);
    const productList = ref([]);
    const currentProducts = ssrRef<ProductInterface[]>([]);
    const isAuth = ref(false);

    const mappedProducts = computed(() => products?.value?.map((product) => ({
      ...product
    })));

    const { getMagentoImage, imageSizes } = useImage();

    /**
     * Chargement des conditions des produits
     */
    const productConditions = async () => {
      priceLoading.value = true;

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      const products = mappedProducts?.value?.map(product => ({ sku: product.sku, pricingUnit: '', quantity: 1 }));

      pricesConditions.value = await fetchProductConditions(
        {
          products,
          tokenId: tokenId,
        }
      );

      priceLoading.value = false;
    };

    onSSR(async () => {
      isAuth.value = await isAuthenticated();

      if (props.mode === 'personal') {
        itemPerPage.value = 3;
      } else if (props.mode === 'personal_extended') {
        itemPerPage.value = 12;
        await loadProducts(1, true);
      }
      products.value = await loadProducts(1, false);
      // On charge les conditions après que les produits soient ok
      if (products.value) {
        await productConditions();
      }
    })

    onMounted(async () => {
      isAuth.value = await isAuthenticated();

      if (props.mode === 'personal') {
        itemPerPage.value = 3;
      } else if (props.mode === 'personal_extended') {
        itemPerPage.value = 12;
        await loadProducts(1, true);
      }
      products.value = await loadProducts(1, false);
      // On charge les conditions après que les produits soient ok
      if (products.value) {
        await productConditions();
      }
    });

    async function getListOfProducts(user, isExtended = false, offset = 1, getFull = false) {
      let products = [];
      let limit = itemPerPage.value * offset;
      let count = 0;

      if (isExtended) {
        if (productList.value.length == 0) {
          productList.value = await loadUserSellingProducts(user?.value?.role?.cod_client);
        }

      } else {
        productList.value = JSON.parse(user?.value?.company?.best_seller);
      }
      if (productList.value && getFull) {
        Object.keys(productList.value).forEach((product) => {
          if (productList.value[product].sku) {
            products.push(productList.value[product].sku);
          }
        });
        totalItems.value = products.length;
        totalPages.value = getTotalPages(products.length, itemPerPage.value);
      } else if (productList.value) {
        Object.keys(productList.value).forEach((product) => {
          if (productList.value[product].sku && count < limit) {
            count++;
            if (count > limit - (itemPerPage.value)) {
              products.push(productList.value[product].sku);
            }
          }
        });
      }
      return products;
    }

    async function reorderProducts(bestSellers) {
      let productList = await getListOfProducts(user, props.mode === 'personal_extended', 1, true);

      let orderedProducts = [];
      Object.keys(productList).forEach((product) => {
        Object.keys(bestSellers.items).forEach((item) => {
          if (productList[product] === bestSellers.items[item].sku) {
            orderedProducts.push(bestSellers.items[item]);
          }
        });
      });

      return orderedProducts;
    }

    async function loadProducts(currentPage = 1, getFull = false) {
      const filters = ref({});
      const pageSize = ref(4);
      const additional = ref({});

      if (props.mode === 'normal') {
        filters.value = {
          flag_promo: { match: 0 },
        };
        additional.value = {
          sort: {
            position: SortEnum.Asc,
          },
          highlightingType: 'BEST_SELLERS',
        }
      } else if (props.mode === 'personal' || props.mode === 'personal_extended') {
        if (user.value === null) {
          await loadUser({ customQuery: { customer: 'customCustomer' } });
        }
        let productList = await getListOfProducts(user, props.mode === 'personal_extended', currentPage, getFull);
        filters.value = {
          sku: { in: productList }
        };
        pageSize.value = props.mode === 'personal_extended' ? itemPerPage.value : 3;
      }

      const bestSellers = await getProductList({
        filter: filters.value,
        pageSize: pageSize.value,
        currentPage: !getFull ? 1 : currentPage,
        ...additional.value
      }, { products: 'customProductsSearch' });

      let best = bestSellers;
      if (best?.items?.length) {
        if (props.mode === 'personal' || props.mode === 'personal_extended') {
          return await reorderProducts(best);
        } else {
          return best.items;
        }
      }
    }

    const loadMoreProducts = async () => {
      let curPage = currentPage.value;

      if (currentProducts.value.length == 0) {
        currentProducts.value = await loadProducts(curPage);
      }

      currentPage.value = curPage + 1;
      let newProducts = await loadProducts(currentPage.value); //Call to API

      newProducts.forEach(p => currentProducts.value.push(p));

      products.value.splice(0, products.value.length); //Empty products
      currentProducts.value.map(p => products.value.push(p)); //Reload products list on page

      await productConditions();
    };

    const getTaxe = (isAuth) => {
      return !isAuth ? ' TTC' : ' HT'
    };

    const getTotalPages = (productLength, itemPerPage) => {
      if(!productLength) {
        return 1;
      }

      return Number.isNaN(Math.ceil(productLength / itemPerPage))
        ? 1
        : Math.ceil(productLength / itemPerPage)
    }

    return {
      addItemToCart,
      getMagentoImage,
      getTaxe,
      imageSizes,
      isInCart,
      loading,
      mappedProducts,
      productGetters,
      priceLoading,
      pricesConditions,
      totalItems,
      showTotals,
      totalPages,
      currentPage,
      calculFlap,
      calculStock,
      loadMoreProducts,
      isAuth,
      getTotalPages
    };
  },
});
