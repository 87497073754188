var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"bloc-x-sell products"},[_vm._m(0),_vm._v(" "),_c('transition-group',{staticClass:"products__grid",attrs:{"appear":"","name":"products__slide","tag":"div"}},_vm._l((_vm.products),function(product,i){return (i < _vm.nbProducts)?_c('PictimeProductCard',{directives:[{name:"e2e",rawName:"v-e2e",value:('category-product-card'),expression:"'category-product-card'"}],key:_vm.productGetters.getSlug(product),staticClass:"products__product-card x-sell",style:({ '--index': i }),attrs:{"product":product,"discount-amount":_vm.isAuth ?
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceExcludingTax.discountAmount :
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceIncludingTax.discountAmount,"discount-percent":_vm.isAuth ?
                      Math.round(_vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceExcludingTax.discountPercentage) :
                      Math.round(_vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceIncludingTax.discountPercentage),"flap":_vm.calculFlap(_vm.isAuth, Object.assign(product, _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku))),"image":_vm.getMagentoImage(_vm.productGetters.getProductThumbnailImage(product)),"is-added-to-cart":false,"is-authenticated":_vm.isAuth,"is-cross-sell":true,"link":_vm.localePath({ name: 'product', params: {slug: product.url_key}}),"old-price":_vm.isAuth ?
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceExcludingTax.regularPrice :
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceIncludingTax.regularPrice,"price-loading":_vm.priceLoading,"regular-price":_vm.isAuth ?
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceExcludingTax.finalPrice :
                      _vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).priceIncludingTax.finalPrice,"reviews-count":_vm.productGetters.getTotalReviews(product),"score-rating":_vm.productGetters.getAverageRating(product),"short-description":_vm.productGetters.getShortDescription(product),"show-add-to-cart-button":false,"special-price":_vm.productGetters.getPrice(product).special && _vm.productGetters.getPrice(product).special,"stocks":_vm.calculStock(_vm.productGetters.getStockCondition(_vm.pricesConditions, product.sku).stocks, 1, product.active),"taxe":_vm.getTaxe(_vm.isAuth),"title":_vm.productGetters.getName(product),"unit-measure":_vm.productGetters.getPriceCondition(_vm.pricesConditions, product.sku).packagingMeasureUnit,"wishlist-icon":_vm.showWishlist ? 'heart' : '',"is-wishlist-popin":_vm.isWishlistPopin},scopedSlots:_vm._u([{key:"image",fn:function(imageSlotProps){return [_c('div',{on:{"click":_vm.toggleAddToCartSidebar}},[_c('SfButton',_vm._g({staticClass:"sf-button--pure sf-product-card__link",attrs:{"link":imageSlotProps.link,"aria-label":"Go To Product","data-testid":"product-link"}},_vm.$listeners),[(Array.isArray(imageSlotProps.image))?_vm._l((imageSlotProps.image.slice(0, 2)),function(picture,key){return _c('nuxt-img',{key:key,staticClass:"sf-product-card__picture image",attrs:{"alt":imageSlotProps.title,"height":imageSlotProps.imageHeight,"src":picture,"width":imageSlotProps.imageWidth}})}):_c('nuxt-img',{staticClass:"sf-product-card__image image",attrs:{"alt":imageSlotProps.title,"height":imageSlotProps.imageHeight,"src":imageSlotProps.image,"width":imageSlotProps.imageWidth}})],2)],1)]}}],null,true)}):_vm._e()}),1),_vm._v(" "),_c('transition-group',{staticClass:"products__list",attrs:{"name":"products__slide","tag":"div"}})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-account-heading"},[_c('h2',{staticClass:"my-account-heading__title"},[_vm._v("\n      Nos produits compatibles\n    ")])])}]

export { render, staticRenderFns }