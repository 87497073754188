<template>
  <div class="stock">
    <div v-if="currentShop" class="stock__store">
      <span class="stock__store-label">
        {{ $t('In your store') }}
      </span>
      <span class="stock__store-name">
         <ShopNameInformation/>
      </span>
    </div>
    <div v-else>
      <div class="stock__store">
      <span class="stock__store-label txt-info">
       Choisissez votre magasin pour voir la disponibilité produits et pouvoir les ajouter au panier
      </span>
      </div>
      <SfButton
        class="stock__store-change store-info"
        @click="openStoreSelector"
      >
        {{ $t('Change of store') }}
      </SfButton>
    </div>

    <div v-if="currentShop" class="stock__content">
      <div class="stock__content__container">
        <div class="stock__content-container-store">
          <SvgImage
            v-if="clickAndCollect"
            class="navbar__filters-icon icon"
            height="6"
            icon="Icon_check_vert"
            width="10"
          />
          <SvgImage
            v-else
            class="navbar__filters-icon icon "
            height="8"
            icon="Icon_fermer"
            width="10"
          />
          <span class="stock__label">{{ storeLabel }}</span>
        </div>
        <SfButton
          class="stock__store-change"
          @click="openStoreSelector"
        >
          {{ $t('Change of store') }}
        </SfButton>
      </div>
      <div class="stock__content__container">
        <SvgImage
          v-if="livraison"
          class="navbar__filters-icon icon"
          height="6"
          icon="Icon_check_vert"
          width="10"
        />
        <SvgImage
          v-else
          class="navbar__filters-icon icon "
          height="8"
          icon="Icon_fermer"
          width="10"
        />
        <span class="stock__label">{{ deliveryLabel }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImage from "~/components/General/SvgImage";
import { SfButton } from '@storefront-ui/vue';
import ShopNameInformation from '~/components/ShopNameInformation';

export default {
  name: "PictimeStockExtended",
  components: {
    SvgImage,
    SfButton,
    ShopNameInformation
  },

  props: {
    store: {
      type: Object,
      default() {
        return { id: 132, name: 'Nanteuil-lès-Meaux' }
      }
    },
    clickAndCollect: {
      type: Boolean,
      default: false,
    },
    storeLabel: {
      type: String,
      default: ""
    },
    livraison: {
      type: Boolean,
      default: false,
    },
    deliveryLabel: {
      type: String,
      default: "Livraison indisponible"
    },
    currentShop: {
      type: String,
      default: "Indisponible magasin"
    }
  },

  methods: {
    openStoreSelector() {
      this.$parent.$parent.$parent.$parent.$emit('Cromology::StoreLocator::Open');
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/components/molecules/pictime-stock-extended.scss";
</style>
