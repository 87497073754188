






























import { SfButton, SfImage, SfSidebar, } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import PaintQtyCalculator from '~/components/molecules/PaintQtyCalculator.vue';
import WallQtyCalculator from '~/components/molecules/WallQtyCalculator.vue';
import FloorQtyCalculator from '~/components/molecules/FloorQtyCalculator.vue';
import PictimeSidebar from '~/components/organisms/PictimeSidebar';

export default defineComponent({
  name: 'AddToCartSidebar',
  components: {
    SfSidebar,
    SfButton,
    SvgImage,
    SfImage,
    PaintQtyCalculator,
    WallQtyCalculator,
    FloorQtyCalculator,
    PictimeSidebar
  },
  props: {
    productType: {
      type: String,
      default: ''
    },
    product: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props) {
    const { isQtyCalculatorSidebarOpen, toggleQtyCalculatorSidebar } = useUiState();
    const title = computed(() => {
      let result = '';
      if (props.productType === 'Peintures pro') {
        result = 'Calculer ma quantité de peinture';
      } else if (props.productType === 'RMS' && props.product.data.attributeValues[0].attribute_set === 'wall_covering') {
        result = 'Calculer ma quantité de papier peint';
      } else if (props.productType === 'RMS' && props.product.data.attributeValues[0].attribute_set === 'flooring') {
        result = 'Calculer ma quantité de revêtement de sol';
      }
      return result;
    });

    return {
      isQtyCalculatorSidebarOpen,
      toggleQtyCalculatorSidebar,
      title
    };
  },
});
