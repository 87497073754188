<template>
  <div class="add-to-cart-qty">
    <QtyCalculatorSidebar
      v-if="isQtyCalculatorSidebarOpen"
      :product="product"
      :product-type="productType"
    />

    <slot name="quantity-select-input" v-bind="{ qty }">
      <SfQuantitySelector
        :disabled="disabled"
        :qty="qty"
        aria-label="Quantity"
        class="sf-add-to-cart__select-quantity"
        @input="$emit('input', $event)"
      />
      <SfButton
        v-if="showQtyCalculator"
        class="qty-calculator"
        @click="openQuantityCalculator()"
      >
        {{ $t('Calculate your quantity') }}
      </SfButton>
    </slot>
  </div>
</template>
<script>
import { SfButton } from '@storefront-ui/vue';
import SfQuantitySelector from "@storefront-ui/vue/src/components/atoms/SfQuantitySelector/SfQuantitySelector.vue";
import QtyCalculatorSidebar from '~/components/QtyCalculatorSidebar.vue';
import { useUiState } from '~/composables';

export default {
  name: "PictimeQuantitySelector",
  components: {
    SfQuantitySelector,
    SfButton,
    QtyCalculatorSidebar
  },
  model: {
    prop: "qty",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    showQtyCalculator: {
      type: Boolean,
      default: false
    },
    productType: {
      type: String,
      default: ""
    },
    product: {
      type: [Object, null],
      default: null,
    },
  },
  setup() {
    const { isQtyCalculatorSidebarOpen, toggleQtyCalculatorSidebar } = useUiState();

    function openQuantityCalculator() {
      toggleQtyCalculatorSidebar();
    }

    return {
      openQuantityCalculator,
      isQtyCalculatorSidebarOpen,
      toggleQtyCalculatorSidebar
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/theme/default/scss/components/organisms/pictime-aside.scss";
</style>
