const wallQtyCalculator = (wallWidth, wallHeight, matchHeight, rollWidth, rollLength, isMarge) => {
  let lesParRouleau = rollLength / (wallHeight + matchHeight);
  let lesNecessaires = wallWidth / rollWidth;
  let res = lesNecessaires / lesParRouleau;

  if (isMarge) {
    res = res + (res * 10 / 100);
  }

  return {
    lesParRouleau: Math.round(lesParRouleau * 100) / 100,
    lesNecessaires: Math.round(lesNecessaires * 100) / 100,
    rouleauxQty: Math.ceil(res)
  }
}

export default wallQtyCalculator;
